/* Configuration */
export const PROD_HOST = 'perpay.com';
const STAGING_HOST = 'grapejuiceclub.com';
export const HOST = window.STAGING ? STAGING_HOST : PROD_HOST;

/* CORE API */
const API_VERSION = 'v2';
const API_SUBDOMAIN = 'ui';
const API_HOST = `https://${API_SUBDOMAIN}.${HOST}`;
export const API_URL_BASE = `${API_HOST}/${API_VERSION}`;

/* Help Center Related */
export const ACTIVE_SHIPPING_HELP_CENTER_URL =
    'https://help.perpay.com/common-perpay-terms/active-shipping';

export const SELF_HELP_URL = `https://${HOST}/self_help_tool/`;
export const SELF_ONBOARDING_VALIDATION_URL = `https://${HOST}/self_onboarding/validation/`;

/* MAGENTO */
const SHOP_SUBDOMAIN = 'shop';

export const MAGENTO_HOST = window.STAGING
    ? `${SHOP_SUBDOMAIN}.${STAGING_HOST}`
    : `${SHOP_SUBDOMAIN}.${PROD_HOST}`;

export const MAGENTO_URL = `https://${MAGENTO_HOST}/`;

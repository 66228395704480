import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useMount } from '@perpay-web/hooks/useMount';
import { authentication } from '@perpay-web/authentication/singleton';
import { SIGNUP_PATH } from '@perpay-web/authentication/constants/paths';
import { paths } from '@perpay-web/fintech/props/appPaths';
import WarningLoaderCircle from '@perpay-web/components/base/WarningLoaderCircle/WarningLoaderCircle';

const useRedirectIfIncomplete = () => {
    const navigate = useNavigate();
    const [isLoadingRedirect, setIsLoadingRedirect] = useState(true);

    useMount(() => {
        const params = new URLSearchParams(window.location.search);
        const postLoginPath = params.get('next') || window.location.pathname;
        params.set('next', postLoginPath);

        const missingRequirements = authentication.getSignupRequirements();
        if (missingRequirements.includes('pho_submit_application')) {
            navigate(`${paths.partnerOnboardingApplication.path}?${params}`, {
                replace: true,
            });
            return;
        }
        if (missingRequirements.length > 0) {
            if (!authentication.getIsPartnerOnboarded()) {
                navigate(`${SIGNUP_PATH}?${params}`, { replace: true });
                return;
            }
        }

        setIsLoadingRedirect(false);
    });

    return {
        isLoadingRedirect,
    };
};

export function withRedirectIfIncomplete(Component) {
    return (props) => {
        const { isLoadingRedirect } = useRedirectIfIncomplete();

        if (isLoadingRedirect) {
            return <WarningLoaderCircle />;
        }

        return <Component {...props} />;
    };
}

/* eslint-disable import/no-unused-modules */
/**
 * The actions in this file should have a consistent format
 *
 * datatype/VERB::NOUN for action strings and
 * DATATYPE_VERB_NOUN for action variables
 *
 * e.g. `const BACKEND_CREATE_CHECKOUT_PAYSTUB = 'backend/CREATE::CHECKOUT_PAYSTUB';`
 */

/* COMPONENT BASED */
export const STORE_UPDATE_ORDERS_FILTER = 'store/UPDATE::ORDERS_FILTER';

export const STORE_OPEN_CANCEL_ORDER_MODAL = 'store/CANCEL_ORDER_MODAL::OPEN';
export const STORE_CLOSE_CANCEL_ORDER_MODAL = 'store/CANCEL_ORDER_MODAL::CLOSE';
export const STORE_REPLACE_ORDER_CANCELLATION_REASONS =
    'store/ORDER_CANCELLATION_REASONS::REPLACE';
export const STORE_RESET_ORDER_CANCELLATION_REASONS =
    'store/ORDER_CANCELLATION_REASONS::RESET';
export const BACKEND_FETCH_ORDER_CANCELLATION_REASONS =
    'backend/ORDER_CANCELLATION_REASONS::FETCH';
export const STORE_ORDER_CANCELLATION_REASONS_ERROR =
    'store/ORDER_CANCELLATION_REASONS::ERROR';

export const BACKEND_FETCH_ORDERS = 'backend/FETCH::ORDERS';
export const STORE_FETCH_ORDERS_ERROR = 'store/ERROR::ORDERS';
export const STORE_REPLACE_ORDERS = 'store/REPLACE::ORDERS';
export const STORE_RESET_ORDERS = 'store/RESET::ORDERS';
export const BACKEND_CANCEL_ORDER = 'backend/ORDER::CANCEL';
export const STORE_CANCEL_ORDER_ERROR = 'store/ORDER::CANCEL::ERROR';
export const BACKEND_CHECKOUT_CREATE_ORDER = 'backend/ORDER::CHECKOUT_CREATE'; // Claim a checkout
export const STORE_CHECKOUT_CREATE_ORDER_ERROR =
    'store/ORDER::CHECKOUT_CREATE_ERROR';
export const STORE_REPLACE_ORDER = 'store/ORDER::REPLACE';
export const BACKEND_CHECKOUT_UPDATE_ORDER =
    'backend/ORDER::CHECKOUT_UPDATE_ORDER';
export const BACKEND_CHECKOUT_REFRESH_ORDER = 'backend/ORDER::CHECKOUT_REFRESH';
export const STORE_CHECKOUT_UPDATE_ORDER_ERROR =
    'store/ORDER::CHECKOUT_UPDATE_ORDER_ERROR';
export const STORE_REPLACE_PAYSTUB = 'store/PAYSTUB::REPLACE';

export const FETCH_USER_INFO = 'USERINFO::FETCH';
export const UPDATE_USER_INFO = 'USERINFO::UPDATE';
export const UPDATE_USER_INFO_ERROR = 'USERINFO::UPDATE::ERROR';
export const REPLACE_USER_INFO = 'USERINFO::REPLACE';
export const BACKEND_FETCH_USER_STATUS = 'backend/USERSTATUS::FETCH';
export const STORE_REPLACE_USER_STATUS = 'store/USERSTATUS::REPLACE';
export const STORE_REPLACE_USER_STATUS_ERROR = 'store/USERSTATUS::ERROR';

export const BACKEND_CREATE_EMPLOYMENT = 'backend/EMPLOYMENT::CREATE';
export const BACKEND_UPDATE_EMPLOYMENT = 'backend/EMPLOYMENT::UPDATE';
export const STORE_UPDATE_EMPLOYMENT_SUCCESS =
    'store/EMPLOYMENT::UPDATE::SUCCESS';

export const BACKEND_CREATE_WITHDRAWAL_REQUEST = 'backend/WITHDRAWAL::CREATE';
export const BACKEND_CREATE_WITHDRAWAL_REQUEST_UI =
    'backend/WITHDRAWAL::CREATE_UI';
export const BACKEND_CREATE_WITHDRAWAL_SUCCESS =
    'backend/WITHDRAWAL::CREATE::SUCCESS';
export const BACKEND_CREATE_WITHDRAWAL_ERROR =
    'backend/WITHDRAWAL::CREATE::ERROR';
export const BACKEND_FETCH_WITHDRAWAL_BALANCE = 'backend/WITHDRAWAL::FETCH';
export const STORE_REPLACE_WITHDRAWAL_BALANCE = 'store/WITHDRAWAL::REPLACE';
export const STORE_REPLACE_WITHDRAWAL_BALANCE_ERROR = 'store/WITHDRAWAL::ERROR';

export const BACKEND_FETCH_ACCOUNT_DEACTIVATION_ELIGIBILITY =
    'backend/ACCOUNT_DEACTIVATION_ELIGIBILITY::FETCH';
export const STORE_REPLACE_ACCOUNT_DEACTIVATION_ELIGIBILITY =
    'store/ACCOUNT_DEACTIVATION_ELIGIBILITY::REPLACE';

export const BACKEND_FETCH_ACCOUNT_DEACTIVATION_REASONS =
    'backend/ACCOUNT_DEACTIVATION_REASONS::FETCH';
export const STORE_REPLACE_ACCOUNT_DEACTIVATION_REASONS =
    'store/ACCOUNT_DEACTIVATION_REASONS::REPLACE';
export const STORE_RESET_ACCOUNT_DEACTIVATION_REASONS =
    'store/ACCOUNT_DEACTIVATION_REASONS::RESET';
export const STORE_ACCOUNT_DEACTIVATION_REASONS_ERROR =
    'store/ACCOUNT_DEACTIVATION_REASONS::ERROR';

export const BACKEND_DEACTIVATE_ACCOUNT = 'backend/ACCOUNT::DEACTIVATE';
export const STORE_DEACTIVATE_ACCOUNT_ERROR =
    'store/ACCOUNT::DEACTIVATE::ERROR';
export const STORE_RESET_DEACTIVATE_ACCOUNT_ERROR =
    'store/ACCOUNT::DEACTIVATE::ERROR::RESET';

export const BACKEND_FETCH_JOBS = 'backend/JOBS::LIST::FETCH';
export const STORE_REPLACE_JOBS = 'store/JOBS::LIST::REPLACE';
export const STORE_REPLACE_JOBS_ERROR = 'store/JOBS::LIST::REPLACE::ERROR';

export const ERROR_COMPANIES = 'store/COMPANIES::ERROR';
export const FETCH_COMPANIES = 'backend/COMPANIES::FETCH';
export const FETCH_COMPANIES_SELF_ONBOARDING =
    'backend/COMPANIES_SELF_ONBOARDING::FETCH';
export const REPLACE_COMPANIES = 'store/COMPANIES::REPLACE';
export const REPLACE_COMPANIES_SELF_ONBOARDING =
    'store/COMPANIES_SELF_ONBOARDING::REPLACE';
export const RESET_COMPANIES = 'store/COMPANIES::RESET';

export const STORE_CHECKOUT_REPLACE_ORDER_UUID =
    'store/CHECKOUT::REPLACE_ORDER_UUID';
export const STORE_CHECKOUT_NEXT_STEP = 'store/CHECKOUT::NEXT_STEP';
export const STORE_CHECKOUT_BACK_STEP = 'store/CHECKOUT::BACK_STEP';
export const STORE_CHECKOUT_RESET_STEP_INDEX =
    'store/CHECKOUT::RESET_STEP_INDEX';
export const BACKEND_CHECKOUT_UPDATE_USER_INFO =
    'backend/CHECKOUT::USER::UPDATE_USER_INFO';
export const BACKEND_CHECKOUT_UPDATE_USER_INFO_ERROR =
    'backend/CHECKOUT::USER::UPDATE_USER_INFO_ERROR';
export const BACKEND_CHECKOUT_VERIFY_IDENTITY =
    'backend/CHECKOUT::USER::VERIFY_IDENTITY';
export const BACKEND_CHECKOUT_VERIFY_IDENTITY_ERROR =
    'backend/CHECKOUT::USER::VERIFY_IDENTITY::ERROR';
export const STORE_CHECKOUT_VERIFY_IDENTITY_SERVICE_ERROR =
    'store/CHECKOUT::USER::VERIFY_IDENTITY::SERVICE_ERROR';
export const BACKEND_CHECKOUT_UPDATE_EMPLOYEE_ID =
    'backend/CHECKOUT::UPDATE_EMPLOYEE_ID';
export const BACKEND_CHECKOUT_UPDATE_EMPLOYEE_ID_ERROR =
    'backend/CHECKOUT::UPDATE_EMPLOYEE_ID_ERROR';
export const STORE_RESET_CHECKOUT = 'store/CHECKOUT::RESET';
export const STORE_CHECKOUT_REPLACE_LOADING_SIGNATURE =
    'store/CHECKOUT::REPLACE_LOADING_SIGNATURE';
export const BACKEND_CHECKOUT_LOAN_DECISION_GENERATE =
    'backend/CHECKOUT::LOAN_DECISION::GENERATE';
export const STORE_CHECKOUT_LOAN_DECISION_GENERATE_ERROR =
    'store/CHECKOUT::LOAN_DECISION::GENERATE::ERROR';
export const STORE_CHECKOUT_REPLACE_LOAN_DECISION =
    'store/CHECKOUT::LOAN_DECISION::REPLACE';
export const STORE_BACK_STEP_FROM_CONFIRM_ORDER =
    'store/CHECKOUT::BACK_STEP_FROM_CONFIRM_ORDER';
export const BACKEND_PAYSTUB_START_POLLING = 'backend/PAYSTUB::START_POLLING';
export const BACKEND_PAYSTUB_POLLING_COMPLETE =
    'backend/PAYSTUB::POLLING_COMPLETE';
export const BACKEND_PAYSTUB_CANCEL_POLLING = 'backend/PAYSTUB::CANCEL_POLLING';
export const STORE_PAYSTUB_POLLING_ERROR = 'store/PAYSTUB::POLLING_ERROR';
export const BACKEND_CREATE_PAYSTUB = 'backend/PAYSTUB::CREATE';

export const BACKEND_FETCH_PAYROLL_INSTRUCTIONS =
    'backend/PAYROLL_INSTRUCTIONS::FETCH';
export const BACKEND_FETCH_PAYROLL_INSTRUCTION_ERROR =
    'backend/PAYROLL_INSTRUCTIONS::FETCH_ERROR';
export const STORE_REPLACE_PAYROLL_INSTRUCTIONS =
    'store/PAYROLL_INSTRUCTIONS::REPLACE';
export const STORE_RESET_PAYROLL_INSTRUCTIONS =
    'store/PAYROLL_INSTRUCTIONS::RESET';

export const BACKEND_FETCH_EXTERNAL_CARDS = 'backend/EXTERNAL_CARDS::FETCH';
export const BACKEND_CREATE_EXTERNAL_CARD = 'backend/EXTERNAL_CARDS::CREATE';
export const STORE_CREATE_EXTERNAL_CARD_SUCCESS =
    'store/EXTERNAL_CARDS::CREATE::SUCCESS';
export const STORE_REPLACE_EXTERNAL_CARDS = 'store/EXTERNAL_CARDS::REPLACE';
export const STORE_RESET_EXTERNAL_CARDS = 'store/EXTERNAL_CARDS::RESET';

export const BACKEND_FETCH_BANKS = 'backend/BANKS::FETCH';
export const STORE_REPLACE_BANKS = 'store/BANKS::REPLACE';
export const STORE_REPLACE_BANKS_ERROR = 'store/BANKS::REPLACE::ERROR';
export const STORE_RESET_BANKS = 'store/BANKS::RESET';
export const BACKEND_CREATE_BANK = 'backend/BANKS::CREATE';
export const STORE_CREATE_BANK_SUCCESS = 'store/BANKS::CREATE::SUCCESS';
export const STORE_CREATE_BANK_ERROR = 'store/BANKS::CREATE::ERROR';
export const BACKEND_VERIFY_BANK = 'backend/BANKS::VERIFY';
export const BACKEND_VERIFY_BANK_SINGLE = 'backend/BANKS::VERIFY_SINGLE';
export const BACKEND_VERIFY_BANK_SELECT = 'backend/BANKS::VERIFY_SELECT';
export const STORE_VERIFY_BANK_ERROR = 'store/BANKS::VERIFY::ERROR';
export const STORE_VERIFY_BANK_SUCCESS = 'store/BANKS::VERIFY::SUCCESS';

export const BACKEND_CREATE_EVENT = 'backend/EVENTS::CREATE';

export const STORE_PAYMENT_PORTAL_OPEN_ADD_EXTERNAL_CARD_MODAL =
    'store/PAYMENT_PORTAL::ADD_EXTERNAL_CARD_MODAL::OPEN';
export const STORE_PAYMENT_PORTAL_CLOSE_ADD_EXTERNAL_CARD_MODAL =
    'store/PAYMENT_PORTAL::ADD_EXTERNAL_CARD_MODAL::CLOSE';
export const STORE_PAYMENT_PORTAL_CLOSE = 'store/PAYMENT_PORTAL::CLOSE';
export const STORE_PAYMENT_PORTAL_RESET = 'store/PAYMENT_PORTAL::RESET';
export const STORE_PAYMENT_PORTAL_CLEAR_ERRORS =
    'store/PAYMENT_PORTAL_ERRORS::CLEAR';

export const BACKEND_CREATE_PAYMENT_PLAN = 'backend/PAYMENT_PLANS::CREATE';
export const STORE_CREATE_PAYMENT_PLAN_SUCCESS =
    'store/PAYMENT_PLANS::CREATE::SUCCESS';
export const STORE_CREATE_PAYMENT_PLAN_ERROR =
    'store/PAYMENT_PLANS::CREATE::ERROR';

export const BACKEND_VERIFY_IDENTITY = 'backend/IDENTITY::VERIFY';
export const STORE_VERIFY_IDENTITY_SUCCESS = 'store/IDENTITY::VERIFY::SUCCESS';
export const STORE_VERIFY_IDENTITY_SERVICE_ERROR =
    'store/IDENTITY::VERIFY::SERVICE_ERROR';
export const STORE_VERIFY_IDENTITY_ERROR = 'store/IDENTITY::VERIFY::ERROR';
export const STORE_SET_VERIFY_IDENTITY_SUCCESS_REDIRECT_PATH =
    'store/IDENTITY::SUCCESS_REDIRECT_PATH::SET';
export const STORE_SET_VERIFY_IDENTITY_CHECKOUT_UUID =
    'store/IDENTITY::CHECKOUT_UUID::SET';
export const STORE_VERIFY_IDENTITY_RESET = 'store/IDENTITY::RESET';
export const STORE_VERIFY_IDENTITY_MOUNTED = 'store/IDENTITY::MOUNTED';

export const BACKEND_FETCH_PINWHEEL_SURVEY = 'backend/SURVEY::PINWHEEL::FETCH';
export const STORE_REPLACE_PINWHEEL_SURVEY = 'store/SURVEY::PINWHEEL::REPLACE';
export const STORE_FETCH_PINWHEEL_SURVEY_ERROR =
    'store/SURVEY::PINWHEEL::FETCH::ERROR';
export const STORE_RESET_PINWHEEL_SURVEY = 'store/SURVEY::PINWHEEL::RESET';
export const STORE_REPLACE_PINWHEEL_SURVEY_QUESTIONS =
    'store/SURVEY_QUESTIONS::PINWHEEL::REPLACE';
export const STORE_REPLACE_PINWHEEL_SURVEY_OPTIONS =
    'store/SURVEY_OPTIONS::PINWHEEL::REPLACE';

export const BACKEND_CREATE_SURVEY_RESPONSE = 'backend/SURVEY_RESPONSE::CREATE';
export const STORE_CREATE_SURVEY_RESPONSE_SUCCESS =
    'store/SURVEY_RESPONSE::CREATE::SUCCESS';
export const STORE_CREATE_SURVEY_RESPONSE_ERROR =
    'store/SURVEY_RESPONSE::CREATE::ERROR';

export const BACKEND_FETCH_AB_TEST = 'backend/AB_TEST::FETCH';
export const STORE_AB_TEST_REPLACE = 'store/AB_TEST::REPLACE';
export const STORE_AB_TEST_ERROR = 'store/AB_TEST::FETCH::ERROR';
export const STORE_AB_TEST_RESET = 'store/AB_TEST::RESET';
export const BACKEND_VERIFY_AB_TEST = 'backend/AB_TEST::VERIFY';
export const STORE_RESET_ATTEMPTED_EMPLOYER_VERIFICATION =
    'store/ATTEMPTED_EMPLOYER_VERIFICATION::RESET';
export const ANALYTICS_REPORT_AB_TEST = 'analytics/AB_TEST::REPORT';

export const STORE_PINWHEEL_RESET = 'store/PINWEEEL::RESET';
export const BACKEND_FETCH_PINWHEEL_ELIGIBILITY =
    'backend/FETCH::PINWHEEL_ELIGIBILITY';

export const BACKEND_CREATE_DEDUCTION = 'backend/DEDUCTION::CREATE';
export const BACKEND_FETCH_DEDUCTIONS = 'backend/DEDUCTIONS::FETCH';
export const BACKEND_UPDATE_SUCCESS_DEDUCTION =
    'backend/SUCCESS_DEDUCTION::UPDATE';
export const BACKEND_UPDATE_SUCCESS_DEDUCTION_ACCOUNT_ID =
    'backend/SUCCESS_DEDUCTION::UPDATE::ACCOUNT_ID';
export const BACKEND_UPDATE_FAILURE_DEDUCTION =
    'backend/FAILURE_DEDUCTION::UPDATE';
export const BACKEND_UPDATE_RETRYING_DEDUCTION =
    'backend/RETRYING_DEDUCTION::UPDATE';
export const STORE_REPLACE_DEDUCTION = 'store/DEDUCTION::REPLACE';
export const STORE_REPLACE_DEDUCTION_ERROR = 'store/DEDUCTION::REPLACE::ERROR';
export const STORE_DEDUCTION_CLEAR_ERRORS = 'store/DEDUCTION::ERROR::CLEAR';
export const STORE_RESET_DEDUCTIONS = 'store/DEDUCTION::RESET';

export const BACKEND_FETCH_FEATURES = 'backend/FEATURES::FETCH';
export const STORE_REPLACE_FEATURES = 'store/FEATURES::REPLACE';
export const STORE_FETCH_FEATURES_ERROR = 'backend/FEATURES::FETCH::ERROR';
export const BACKEND_FEATURES_ENROLL = 'backend/FEATURES::ENROLL';
export const BACKEND_FEATURES_REENROLL = 'backend/FEATURES::REENROLL';
export const BACKEND_FEATURES_REENABLE = 'backend/FEATURES::REENABLE';
export const BACKEND_FEATURES_DISABLE = 'backend/FEATURES::DISABLE';
export const STORE_FEATURES_ENROLL_SUCCESS = 'store/FEATURES::ENROLL::SUCCESS';
export const STORE_FEATURES_ENROLL_ERROR = 'store/FEATURES::ENROLL::ERROR';
export const BACKEND_FETCH_FEATURE_ENROLLMENTS =
    'backend/FEATURE_ENROLLMENTS::FETCH';
export const STORE_REPLACE_FEATURE_ENROLLMENTS =
    'store/FEATURE_ENROLLMENTS::REPLACE';
export const STORE_FETCH_FEATURE_ENROLLMENTS_ERROR =
    'store/FEATURE_ENROLLMENTS::FETCH::ERROR';
export const STORE_RESET_FEATURE_ENROLLMENTS =
    'store/FEATURE_ENROLLMENTS::RESET';

export const BACKEND_FETCH_CREDIT_SCORES_SUMMARY =
    'backend/CREDIT_SCORES_SUMMARY::FETCH';
export const STORE_REPLACE_CREDIT_SCORES_SUMMARY =
    'store/CREDIT_SCORES_SUMMARY::REPLACE';
export const STORE_FETCH_CREDIT_SCORES_SUMMARY_ERROR =
    'store/CREDIT_SCORES_SUMMARY::FETCH::ERROR';
export const STORE_RESET_CREDIT_SCORES_SUMMARY =
    'store/CREDIT_SCORES_SUMMARY::RESET';

export const BACKEND_CARD_ACCOUNT_APPLICATION_START_POLLING =
    'backend/CARD_ACCOUNT_APPLICATION::POLLING::START';
export const BACKEND_CARD_ACCOUNT_APPLICATION_CANCEL_POLLING =
    'backend/CARD_ACCOUNT_APPLICATION::POLLING::CANCEL';
export const BACKEND_CARD_ACCOUNT_APPLICATION_STOP_POLLING =
    'backend/CARD_ACCOUNT_APPLICATION::POLLING::STOP';
export const STORE_CARD_ACCOUNT_APPLICATION_POLLING_ERROR =
    'store/CARD_ACCOUNT_APPLICATION::POLLING::ERROR';

export const BACKEND_CARD_ACTIVATE = 'backend/CARD::ACTIVATE';
export const STORE_CARD_ACTIVATE_SUCCESS = 'store/CARD::ACTIVATE::SUCCESS';
export const STORE_CARD_ACTIVATE_ERROR = 'store/CARD::ACTIVATE::ERROR';
export const STORE_CARD_ACTIVATE_REPLACE_LAST_FOUR =
    'store/CARD::ACTIVATE::LAST_FOUR::REPLACE';

export const BACKEND_CARD_UPDATE_USER_INFO = 'backend/CARD::USER_INFO::UPDATE';
export const BACKEND_CARD_UPDATE_USER_INFO_ERROR =
    'backend/CARD::USER::UPDATE_USER_INFO_ERROR';

export const FETCH_NOTIFICATIONS = 'backend/NOTIFICATIONS::FETCH';
export const FETCH_NOTIFICATIONS_SUCCESS =
    'store/NOTIFICATIONS::FETCH::SUCCESS';
export const FETCH_NOTIFICATIONS_ERROR = 'store/NOTIFICATIONS::FETCH::ERROR';
export const ACKNOWLEDGE_NOTIFICATION = 'backend/NOTIFICATIONS::ACK';
export const ACKNOWLEDGE_NOTIFICATION_SUCCESS =
    'store/NOTIFICATIONS::ACK::SUCCESS';
export const ACKNOWLEDGE_NOTIFICATION_ERROR = 'store/NOTIFICATIONS::ACK::ERROR';

export const STORE_CARD_CONFIRM_EMPLOYER_NEXT_STEP =
    'store/CARD::CONFIRM_EMPLOYER::NEXT_STEP';

/* FLOW BASED */
/* AUTHENTICATION RELATED */
export const BACKEND_FETCH_AUTH_WITH_CREDENTIALS =
    'backend/FETCH::AUTH_WITH_CREDENTIALS';
export const BACKEND_FETCH_AUTH_WITH_CREDENTIALS_ERROR =
    'store/FETCH::AUTH_WITH_CREDENTIALS_ERROR';
export const BACKEND_FETCH_AUTH_WITH_REFRESH_TOKEN =
    'backend/FETCH::AUTH_WITH_REFRESH_TOKEN';
export const BACKEND_FETCH_RECAPTCHA_ACTIVE = 'backend/FETCH::RECAPTCHA_ACTIVE';
export const STORE_REPLACE_AUTH = 'store/REPLACE::AUTH';
export const STORE_REPLACE_RECAPTCHA_ACTIVE = 'store/REPLACE::RECAPTCHA_ACTIVE';
export const STORE_RESET_RECAPTCHA = 'store/RESET::RECAPTCHA';

export const BACKEND_POST_PASSWORD_RESET = 'backend/POST::PASSWORD_RESET';
export const STORE_REPLACE_PASSWORD_RESET_SUCCESS =
    'store/POST::PASSWORD_RESET_SUCCESS';
export const STORE_REPLACE_PASSWORD_RESET_ERROR =
    'store/POST::PASSWORD_RESET_ERROR';
export const STORE_CLEAR_PASSWORD_RESET = 'store/PASSWORD_RESET::CLEAR';

// TODO: Make these actions consistent with the rest of our actions
//       ie. backend/POST::PRIMARY_JOB
export const BACKEND_CREATE_PRIMARY_JOB = 'backend/PRIMARY_JOB::CREATE';
export const BACKEND_UPDATE_SALARY_INFO = 'backend/SALARY_INFO::UPDATE';
export const BACKEND_CREATE_PHONE = 'backend/PHONE::CREATE';
export const BACKEND_SIGNUP = 'backend/SIGNUP';
export const BACKEND_VERIFY_PHONE = 'backend/PHONE::VERIFY';
export const BACKEND_VERIFY_CARD_INVITE_CODE =
    'backend/CARD_INVITE_CODE::VERIFY';
export const STORE_CREATE_PRIMARY_JOB_ERROR = 'store/CREATE_PRIMARY_JOB::ERROR';
export const STORE_REPLACE_PRIMARY_JOB = 'store/PRIMARY_JOB::REPLACE';
export const STORE_UPDATE_SALARY_INFO_ERROR = 'store/UPDATE_SALARY_INFO::ERROR';
export const STORE_REPLACE_SALARY_INFO = 'store/SALARY_INFO::REPLACE';
export const STORE_CREATE_PHONE_ERROR = 'store/CREATE_PHONE::ERROR';
export const STORE_REPLACE_PHONE = 'store/PHONE::REPLACE';
export const STORE_SIGNUP_ERROR = 'store/SIGNUP_ERROR';
export const STORE_SIGNUP_SUCCESS = 'store/SIGNUP_SUCCESS';
export const STORE_VERIFY_PHONE_ERROR = 'store/VERIFY_PHONE::ERROR';
export const STORE_VERIFY_PHONE_SUCCESS = 'store/VERIFY_PHONE::SUCCESS';
export const STORE_VERIFY_INVITE_CARD_CODE_SUCCESS =
    'store/VERIFY_CARD_INVITE_CODE::SUCCESS';
export const STORE_VERIFY_INVITE_CARD_CODE_ERROR =
    'store/VERIFY_CARD_INVITE_CODE::ERROR';
export const STORE_RESET_SIGNUP_BACKEND_ERRORS =
    'store/BACKEND_SIGNUP_ERRORS::RESET';
export const STORE_CLEAR_CARD_INVITE = 'store/CARD_INVITE::CLEAR';
export const STORE_PHONE_BACK_STEP = 'store/PHONE_BACK_STEP';
export const BACKEND_FETCH_ACCOUNT_SUMMARY = 'backend/ACCOUNT_SUMMARY::FETCH';
export const STORE_ACCOUNT_SUMMARY_REPLACE = 'store/ACCOUNT_SUMMARY::REPLACE';
export const STORE_ACCOUNT_SUMMARY_ERROR = 'store/ACCOUNT_SUMMARY::ERROR';
export const STORE_LANDING_PAGE_CLICK_PROFILE =
    'store/CLICK::LANDING_PAGE_PROFILE';

/* EPIC BASED */
export const FETCH_PAYMENT_SETUP_DATA = 'epic/PAYMENT_SETUP_DATA::FETCH';
export const FETCH_PAYMENT_SETUP_DATA_SUCCESS =
    'epic/PAYMENT_SETUP_DATA::FETCH::SUCCESS';
export const FETCH_PAYMENT_SETUP_DATA_ERROR =
    'epic/PAYMENT_SETUP_DATA::FETCH::ERROR';
export const PAYMENT_SETUP_CREATE_PRIMARY_JOB =
    'epic/PAYMENT_SETUP::PRIMARY_JOB::CREATE';
export const FETCH_PERPAY_SPLIT_SET_UP_PAYMENTS_DATA =
    'epic/PERPAY_SPLIT_SET_UP_PAYMENTS_DATA::FETCH';
export const FETCH_PERPAY_SPLIT_SET_UP_PAYMENTS_DATA_SUCCESS =
    'epic/PERPAY_SPLIT_SET_UP_PAYMENTS_DATA::SUCCESS';
export const FETCH_PERPAY_SPLIT_SET_UP_PAYMENTS_DATA_ERROR =
    'epic/PERPAY_SPLIT_SET_UP_PAYMENTS_DATA::ERROR';
export const PERPAY_SPLIT_UPDATE_SALARY_INFO =
    'epic/PERPAY_SPLIT::SALARY_INFO::UPDATE';
export const PERPAY_SPLIT_CREATE_PRIMARY_JOB =
    'epic/PERPAY_SPLIT::PRIMARY_JOB::CREATE';
export const PERPAY_SPLIT_CREATE_BANK = 'epic/PERPAY_SPLIT_BANK::CREATE';
export const PINWHEEL_SPLIT_UPDATE_SALARY_INFO =
    'epic/PINWHEEL_SPLIT::SALARY_INFO::UPDATE';
export const PINWHEEL_SPLIT_CREATE_PRIMARY_JOB =
    'epic/PINWHEEL_SPLIT::PRIMARY_JOB::CREATE';
export const BANK_SCREEN_CREATE_BANK = 'epic/BANK_SCREEN_BANK::CREATE';
export const PROFILE_CREATE_EXTERNAL_CARD =
    'epic/PROFILE::EXTERNAL_CARD::CREATE';
export const PAYMENT_PORTAL_CREATE_EXTERNAL_CARD =
    'epic/PAYMENT_PORTAL::EXTERNAL_CARD::CREATE';
export const PINWHEEL_CREATE_PRIMARY_JOB = 'epic/PINWHEEL::PRIMARY_JOB::CREATE';
export const PINWHEEL_UPDATE_SALARY_INFO = 'epic/UPDATE::SALARY_INFO::UPDATE';
export const CARD_UPDATE_SALARY_INFO = 'epic/CARD::SALARY_INFO::UPDATE';
export const CARD_CREATE_PRIMARY_JOB = 'epic/CARD::PRIMARY_JOB::CREATE';
export const CARD_PROCESS_APPLICATION = 'epic/CARD::APPLICATION::PROCESS';
export const CARD_PROCESS_APPLICATION_ADDRESS =
    'epic/CARD::APPLICATION_ADDRESS::PROCESS';

export const STORE_CARD_CREATE_AUTO_PAY_REPLACE_SELECTED_BANK_UUID =
    'store/CARD_CREATE_AUTO_PAY::SELECTED_BANK_UUID::REPLACE';
export const STORE_CARD_CREATE_AUTO_PAY_REPLACE_FREQUENCY =
    'store/CARD_CREATE_AUTO_PAY::SELECTED_FREQUENCY::REPLACE';
export const STORE_CARD_CREATE_AUTO_PAY_SET_INITIAL_SELECTED_FREQUENCY =
    'store/CARD_CREATE_AUTO_PAY::INITIAL_SELECTED_FREQUENCY::SET';
export const STORE_CARD_CREATE_AUTO_PAY_REPLACE_DATE =
    'store/CARD_CREATE_AUTO_PAY::SELECTED_DATE::REPLACE';
export const STORE_CARD_CREATE_AUTO_PAY_REPLACE_DATE_TYPE =
    'store/CARD_CREATE_AUTO_PAY::SELECTED_DATE_TYPE::REPLACE';
export const STORE_CARD_CREATE_AUTO_PAY_REPLACE_AMOUNT =
    'store/CARD_CREATE_AUTO_PAY::AMOUNT::REPLACE';
export const STORE_CARD_CREATE_AUTO_PAY_REPLACE_AMOUNT_TYPE =
    'store/CARD_CREATE_AUTO_PAY::AMOUNT_TYPE::REPLACE';
export const STORE_CARD_CREATE_AUTO_PAY_RESET_DATE_AND_AMOUNT =
    'store/CARD_CREATE_AUTO_PAY::AMOUNT_AND_DATE_DATA::RESET';
export const STORE_CARD_CREATE_AUTO_PAY_RESET =
    'store/CARD_CREATE_AUTO_PAY::RESET';

export const STORE_UPDATE_DIRECT_DEPOSIT_REPLACE_AMOUNT_TO_CHARGE =
    'store/UPDATE_DIRECT_DEPOSIT::AMOUNT_TO_CHARGE::REPLACE';
export const STORE_UPDATE_DIRECT_DEPOSIT_REPLACE_AMOUNT_TYPE =
    'store/UPDATE_DIRECT_DEPOSIT::AMOUNT_TYPE::REPLACE';
export const STORE_UPDATE_DIRECT_DEPOSIT_REPLACE_COMPANY_NAME =
    'store/UPDATE_DIRECT_DEPOSIT::COMPANY_NAME::REPLACE';
export const UPDATE_DIRECT_DEPOSIT_CREATE_PRIMARY_JOB =
    'backend/UPDATE_DIRECT_DEPOSIT::PRIMARY_JOB::CREATE';

/* ROUTE BASED */
export const ROUTE_TO_LOCATION = 'ROUTE::LOCATION';
export const ROUTE_TO_JWT_LOCATION = 'ROUTE::JWT_LOCATION';
export const POST_AUTH_REDIRECT = 'POST_AUTH_REDIRECT';

export const APP_UPDATE_POLL_START = 'store/APP_UPDATE_POLL::START';
export const APP_UPDATE_POLL_STOP = 'store/APP_UPDATE_POLL::STOP';
export const APP_UPDATE_QUEUE_REFRESH = 'APP_UPDATE_REFRESH::QUEUE';

/* SERVICE BASED */
export const SEGMENT_MIDDLEWARE_READY = 'store/SEGMENT_MIDDLEWARE::READY';
export const FACEBOOK_MIDDLEWARE_READY = 'store/FACEBOOK_MIDDLEWARE::READY';

/* ANALYTICS BASED */
export const INITIALIZE_IDENTITY = 'INITIALIZE_IDENTITY';

export const ANALYTICS_CREATE_APPLICATION_SUBMITTED =
    'ANALYTICS::APPLICATION_SUBMITTED::CREATE';
export const ANALYTICS_CREATE_PURCHASE_SUBMITTED_FROM_MAGENTO =
    'ANALYTICS::PURCHASE_SUBMITTED_FROM_MAGENTO::CREATE';
export const ANALYTICS_CREATE_UPDATED_PII = 'ANALYTICS::UPDATED_PII::CREATE';
export const ANALYTICS_IDENTIFY_USER = 'ANALYTICS::IDENTIFY_USER';
export const ANALYTICS_MARK_AB_TEST_VARIANT_UNAUTHENTICATED =
    'ANALYTICS::AB_TEST_UNAUTHENTICATED::MARK_VARIANT';
export const ANALYTICS_MARK_AB_TEST_VARIANT_AUTHENTICATED =
    'ANALYTICS::AB_TEST_AUTHENTICATED::MARK_VARIANT';

export const ANALYTICS_PAYMENT_PORTAL_OPEN_STEP_SELECT_AMOUNT =
    'ANALYTICS::PAYMENT_PORTAL::OPEN_STEP::SELECT_AMOUNT';
export const ANALYTICS_PAYMENT_PORTAL_OPEN_STEP_SELECT_EXTERNAL_CARD =
    'ANALYTICS::PAYMENT_PORTAL::OPEN_STEP::SELECT_CARD';
export const ANALYTICS_PAYMENT_PORTAL_OPEN_STEP_CHARGE_COMPLETE =
    'ANALYTICS::PAYMENT_PORTAL::OPEN_STEP::CHARGE_COMPLETE';
export const ANALYTICS_PAYMENT_PORTAL_OPEN_STEP_SELECT_PAYMENT_OPTION =
    'ANALYTICS::PAYMENT_PORTAL::OPEN_STEP::SELECT_PAYMENT_OPTION';
export const ANALYTICS_PAYMENT_PORTAL_OPEN_STEP_SELECT_SCHEDULE =
    'ANALYTICS::PAYMENT_PORTAL::OPEN_STEP::SELECT_SCHEDULE';
export const ANALYTICS_PAYMENT_PORTAL_OPEN_STEP_SELECT_SCHEDULE_START =
    'ANALYTICS::PAYMENT_PORTAL::OPEN_STEP::SELECT_SCHEDULE_START';
export const ANALYTICS_PAYMENT_PORTAL_OPEN_STEP_PAYMENT_PLAN_CREATED =
    'ANALYTICS::PAYMENT_PORTAL::OPEN_STEP::PAYMENT_PLAN_CREATED';
export const ANALYTICS_PAYMENT_PORTAL_OPEN_SELF_HELP_TOOL =
    'ANALYTICS::PAYMENT_PORTAL::OPEN_SELF_HELP_TOOL';
export const ANALYTICS_PAYMENT_PORTAL_CLICK_ADD_EXTERNAL_CARD =
    'ANALYTICS::PAYMENT_PORTAL::CLICK_ADD_CARD';
export const ANALYTICS_PAYMENT_PORTAL_CLOSE_ADD_EXTERNAL_CARD_MODAL =
    'ANALYTICS::PAYMENT_PORTAL::CLOSE_ADD_CARD_MODAL';
export const ANALYTICS_PAYMENT_PORTAL_SUBMIT_ADD_EXTERNAL_CARD =
    'ANALYTICS::PAYMENT_PORTAL::SUBMIT_ADD_CARD';
export const ANALYTICS_PAYMENT_PORTAL_SUBMIT_CHARGE_CUSTOM =
    'ANALYTICS::PAYMENT_PORTAL::SUBMIT_CHARGE::CUSTOM';
export const ANALYTICS_PAYMENT_PORTAL_SUBMIT_CHARGE_MINIMUM_PAYMENT =
    'ANALYTICS::PAYMENT_PORTAL::SUBMIT_CHARGE::MINIMUM_PAYMENT';
export const ANALYTICS_PAYMENT_PORTAL_SUBMIT_CHARGE_PAST_DUE =
    'ANALYTICS::PAYMENT_PORTAL::SUBMIT_CHARGE::PAST_DUE';
export const ANALYTICS_PAYMENT_PORTAL_CLICK_DONE =
    'ANALYTICS::PAYMENT_PORTAL::CLICK_DONE';
export const ANALYTICS_PAYMENT_PORTAL_HOMEPAGE_SELECT_RECURRING =
    'ANALYTICS::PAYMENT_PORTAL::HOMEPAGE::SELECT_RECURRING';
export const ANALYTICS_PAYMENT_PORTAL_HOMEPAGE_SELECT_ONE_TIME =
    'ANALYTICS::PAYMENT_PORTAL::HOMEPAGE::SELECT_ONE_TIME';
export const ANALYTICS_PAYMENT_PORTAL_SUBMIT_PLAN_MINIMUM_PAYMENT =
    'ANALYTICS::PAYMENT_PORTAL::SUBMIT_PLAN::MINIMUM_PAYMENT';
export const ANALYTICS_PAYMENT_PORTAL_SUBMIT_PLAN_FIFTY_PERCENT =
    'ANALYTICS::PAYMENT_PORTAL::SUBMIT_PLAN::FIFTY_PERCENT';

export const ANALYTICS_ONBOARDING_ADD_COMPANY =
    'ANALYTICS::ONBOARDING_ADD_COMPANY';
export const ANALYTICS_ONBOARDING_ADD_PHONE_NUMBER =
    'ANALYTICS::ONBOARDING_ADD_PHONE_NUMBER';
export const ANALYTICS_ONBOARDING_VERIFY_PHONE_NUMBER =
    'ANALYTICS::ONBOARDING_VERIFY_PHONE_NUMBER';
export const ANALYTICS_ONBOARDING_ADD_NET_PAY =
    'ANALYTICS::ONBOARDING_ADD_NET_PAY';
export const ANALYTICS_ONBOARDING_EMPLOYER_REJECTED =
    'ANALYTICS::ONBOARDING_EMPLOYER_REJECTED';
export const ANALYTICS_ONBOARDING_EMPLOYER_REJECTED_CLICK_ADD_NEW_EMPLOYER =
    'ANALYTICS::ONBOARDING_EMPLOYER_REJECTED_CLICK_ADD_NEW_EMPLOYER';
export const ANALYTICS_ONBOARDING_EMPLOYER_REJECTED_CLICK_MOVE_TO_MARKETPLACE_SIGNUP =
    'ANALYTICS::ONBOARDING_EMPLOYER_REJECTED_CLICK_MOVE_TO_MARKETPLACE_SIGNUP';
export const ANALYTICS_SIGNUP_SUCCESS = 'ANALYTICS::SIGNUP_SUCCESS';
export const ANALYTICS_LANDING_PAGE_CLICK_STOREFRONT =
    'ANALYTICS::LANDING_PAGE_CLICK_STOREFRONT';
export const ANALYTICS_LANDING_PAGE_CLICK_PROFILE =
    'ANALYTICS::LANDING_PAGE_CLICK_PROFILE';
export const ANALYTICS_SELF_HELP_OPENED = 'ANALYTICS::SELF_HELP_OPENED';
export const ANALYTICS_PROFILE_INFORMATION_UPDATED =
    'ANALYTICS::PROFILE_INFORMATION_UPDATED';
export const ANALYTICS_APPLICATION_SUBMIT_PERSONAL_INFO =
    'ANALYTICS::APPLICATION_SUBMIT_PERSONAL_INFO';
export const ANALYTICS_APPLICATION_SELECT_PAYMENT_OPTIONS =
    'ANALYTICS::APPLICATION_SELECT_PAYMENT_OPTIONS';
export const ANALYTICS_APPLICATION_ATTEMPT_IDENTITY_VERIFICATION =
    'ANALYTICS::APPLICATION_ATTEMPT_IDENTITY_VERIFICATION';
export const ANALYTICS_APPLICATION_UPLOAD_PAYSTUB =
    'ANALYTICS::APPLICATION_UPLOAD_PAYSTUB';
export const ANALYTICS_APPLICATION_DECISION = 'ANALYTICS::APPLICATION_DECISION';
export const ANALYTICS_PAYROLL_INSTRUCTIONS_VIEWED =
    'ANALYTICS::PAYROLL_INSTRUCTIONS_VIEWED';
export const ANALYTICS_PAYROLL_INSTRUCTIONS_FINISH_CLICKED =
    'ANALYTICS::PAYROLL_INSTRUCTIONS_FINISH_CLICKED';
export const ANALYTICS_ATTEMPT_IDENTITY_VERIFICATION =
    'ANALYTICS::ATTEMPT_IDENTITY_VERIFICATION';
export const ANALYTICS_IDENTITY_VERIFICATION_FAILURE =
    'ANALYTICS::IDENTITY_VERIFICATION_FAILURE';
export const ANALYTICS_IDENTITY_VERIFICATION_CLICK_CONTACT_US =
    'ANALYTICS::IDENTITY_VERIFICATION::CLICK_CONTACT_US';
export const ANALYTICS_CARD_QUALIFY_PAGE_VIEW =
    'ANALYTICS::CARD_QUALIFY_PAGE_VIEW';
export const ANALYTICS_CLICK_CARD_SKIP_BUTTON =
    'ANALYTICS::CLICK_CARD_SKIP_BUTTON';
export const ANALYTICS_CLICK_CARD_QUALIFY_BUTTON =
    'ANALYTICS::CLICK_CARD_QUALIFY_BUTTON';

export const ANALYTICS_DASHBOARD_CARD_LEARN_DIRECT_DEPOSIT =
    'ANALYTICS::DASHBOARD::CARD_LEARN_DIRECT_DEPOSIT';
export const ANALYTICS_DASHBOARD_CARD_LEARN_REWARDS =
    'ANALYTICS::DASHBOARD::CARD_LEARN_REWARDS';
export const ANALYTICS_DASHBOARD_LEARN_PERPAY_WORKS =
    'ANALYTICS::DASHBOARD::LEARN_PERPAY_WORKS';
export const ANALYTICS_DASHBOARD_LEARN_CREDIT_BUILDING =
    'ANALYTICS::DASHBOARD::LEARN_CREDIT_BUILDING';
export const ANALYTICS_DASHBOARD_LEARN_SETUP_PAYMENT =
    'ANALYTICS::DASHBOARD::LEARN_SETUP_PAYMENT';
export const ANALYTICS_DASHBOARD_MARKETPLACE_SHOPPING =
    'ANALYTICS::DASHBOARD::MARKETPLACE_START_SHOPPING';
export const ANALYTICS_DASHBOARD_MARKETPLACE_CLICK_HEADER =
    'ANALYTICS::DASHBOARD::MARKETPLACE::CLICK_HEADER';
export const ANALYTICS_DASHBOARD_NOTIFICATION_BAR_CTA_CLICK =
    'ANALYTICS::DASHBOARD::NOTIFICATION_BAR_CTA_CLICK';

export const ANALYTICS_DASHBOARD_LEARN_MORE_CLICK_SHOPPING_AT_PERPAY =
    'ANALYTICS:DASHBOARD::LEARN_MORE::CLICK_SHOPPING_AT_PERPAY';
export const ANALYTICS_DASHBOARD_LEARN_MORE_CLICK_SHIPPING_TIMEFRAMES =
    'ANALYTICS:DASHBOARD::LEARN_MORE::CLICK_SHIPPING_TIMEFRAMES';
export const ANALYTICS_DASHBOARD_LEARN_MORE_CLICK_DIRECT_DEPOSIT =
    'ANALYTICS:DASHBOARD::LEARN_MORE::CLICK_DIRECT_DEPOSIT';
export const ANALYTICS_DASHBOARD_LEARN_MORE_CLICK_BUILD_CREDIT =
    'ANALYTICS:DASHBOARD::LEARN_MORE::CLICK_BUILD_CREDIT';

export const ANALYTICS_DASHBOARD_ALERT_CTA_CLICK =
    'ANALYTICS::DASHBOARD::ALERT_CTA_CLICK';

export const ANALYTICS_DIRECT_DEPOSIT_SUMMARY_CLICK_CARD =
    'ANALYTICS::DIRECT_DEPOSIT::SUMMARY::CLICK_CARD';
export const ANALYTICS_DIRECT_DEPOSIT_DASHBOARD_CLICK_CARD =
    'ANALYTICS::DIRECT_DEPOSIT::DASHBOARD::CLICK_CARD';
export const ANALYTICS_DIRECT_DEPOSIT_DETAILS_PAGE_VIEW =
    'ANALYTICS::DIRECT_DEPOSIT::DETAILS::PAGE_VIEW';
export const ANALYTICS_DIRECT_DEPOSIT_DETAILS_SUMMARY_DROP_DOWN_OPEN =
    'ANALYTICS::DIRECT_DEPOSIT::DETAILS::SUMMARY_DROP_DOWN_OPEN';
export const ANALYTICS_DIRECT_DEPOSIT_DETAILS_SUMMARY_DROP_DOWN_CLOSE =
    'ANALYTICS::DIRECT_DEPOSIT::DETAILS::SUMMARY_DROP_DOWN_CLOSE';
export const ANALYTICS_DIRECT_DEPOSIT_ACCOUNT_LEDGER_CLICK_VIEW_MORE =
    'ANALYTICS::DIRECT_DEPOSIT::ACCOUNT_LEDGER::CLICK_VIEW_MORE';
export const ANALYTICS_DIRECT_DEPOSIT_ACTIVITY_PAGE_VIEW =
    'ANALYTICS::DIRECT_DEPOSIT::ACTIVITY::PAGE_VIEW';
export const ANALYTICS_DIRECT_DEPOSIT_ACTIVITY_CLICK_BACK =
    'ANALYTICS::DIRECT_DEPOSIT::ACTIVITY::CLICK_BACK';
export const ANALYTICS_DIRECT_DEPOSIT_ACTIVITY_CLICK_PAGE =
    'ANALYTICS::DIRECT_DEPOSIT::ACTIVITY::CLICK_PAGE';
export const ANALYTICS_DIRECT_DEPOSIT_EXPLAINER_MODAL_OPEN =
    'ANALYTICS::DIRECT::DEPOSIT::EXPLAINER::MODAL::OPEN';
export const ANALYTICS_DIRECT_DEPOSIT_EXPLAINER_MODAL_CLOSE =
    'ANALYTICS::DIRECT::DEPOSIT::EXPLAINER::MODAL::CLOSE';

export const ANALYTICS_PINWHEEL_OPEN_HOMEPAGE =
    'ANALYTICS::PINWHEEL::OPEN_STEP::HOMEPAGE';
export const ANALYTICS_PINWHEEL_OPEN_CONFIRM_EMPLOYER =
    'ANALYTICS::PINWHEEL::OPEN_STEP::CONFIRM_EMPLOYER';
export const ANALYTICS_PINWHEEL_OPEN_SELECT_AMOUNT =
    'ANALYTICS::PINWHEEL::OPEN_STEP::SELECT_AMOUNT';
export const ANALYTICS_PINWHEEL_OPEN_INTRO =
    'ANALYTICS::PINWHEEL::OPEN_STEP::INTRO';
export const ANALYTICS_PINWHEEL_OPEN_INTRO_CURRENT_DEDUCTION =
    'ANALYTICS::PINWHEEL::OPEN_STEP::INTRO_RETURNING_BORROWER';
export const ANALYTICS_PINWHEEL_OPEN_SUCCESS =
    'ANALYTICS::PINWHEEL::OPEN_STEP::SUCCESS';
export const ANALYTICS_PINWHEEL_INTRO_SELECT_MANUAL_SETUP =
    'ANALYTICS::PINWHEEL::INTRO_STEP::SELECT_MANUAL_SETUP';
export const ANALYTICS_PINWHEEL_INTRO_SELECT_CONTINUE =
    'ANALYTICS::PINWHEEL::INTRO_STEP::SELECT_CONTINUE';
export const ANALYTICS_PINWHEEL_INTRO_INSTANT_SHIP_MODAL_VIEW =
    'ANALYTICS::PINWHEEL::INSTANT_SHIP_MODAL::VIEW';
export const ANALYTICS_PINWHEEL_INTRO_INSTANT_SHIP_MODAL_CLOSE =
    'ANALYTICS::PINWHEEL::INSTANT_SHIP_MODAL::CLOSE';
export const ANALYTICS_PINWHEEL_EARLY_INSTANT_SHIP_MODAL_VIEW =
    'ANALYTICS::PINWHEEL::EARLY_INSTANT_SHIP_MODAL::VIEW';
export const ANALYTICS_PINWHEEL_EARLY_INSTANT_SHIP_MODAL_CLOSE =
    'ANALYTICS::PINWHEEL::EARLY_INSTANT_SHIP_MODAL::CLOSE';
export const ANALYTICS_PINWHEEL_OPEN_EXIT_SURVEY =
    'ANALYTICS::PINWHEEL::OPEN_STEP::EXIT_SURVEY';
export const ANALYTICS_PINWHEEL_SUBMIT_EXIT_SURVEY =
    'ANALYTICS::PINWHEEL::SUBMIT::EXIT_SURVEY';
export const ANALYTICS_PINWHEEL_OPEN_FAILURE =
    'ANALYTICS::PINWHEEL::OPEN_STEP::FAILURE';
export const ANALYTICS_PINWHEEL_OPEN_PINWHEEL_UNAVAILABLE =
    'ANALYTICS::PINWHEEL::OPEN_STEP::PINWHEEL_UNAVAILABLE';
export const ANALYTICS_PINWHEEL_EMPLOYER_UPDATED =
    'ANALYTICS::PINWHEEL::EMPLOYER_UPDATED';
export const ANALYTICS_PINWHEEL_MINIMUM_PAYMENT_SELECTED =
    'ANALYTICS::PINWHEEL::MINIMUM_PAYMENT_SELECTED';
export const ANALYTICS_PINWHEEL_CUSTOM_AMOUNT_SELECTED =
    'ANALYTICS::PINWHEEL::CUSTOM_AMOUNT_SELECTED';
export const ANALYTICS_PINWHEEL_OPEN_ABORT_LANDING =
    'ANALYTICS::PINWHEEL::OPEN_STEP::ABORT_LANDING';
export const ANALYTICS_PINWHEEL_OPEN_PINWHEEL_MODAL =
    'ANALYTICS::PINWHEEL::OPEN_STEP::PINWHEEL_MODAL';
export const ANALYTICS_PINWHEEL_MODAL_EVENT =
    'ANALYTICS::PINWHEEL::MODAL::EVENT';
export const ANALYTICS_PINWHEEL_OPEN_PRIVACY_POLICY =
    'ANALYTICS::PINWHEEL::OPEN_PRIVACY_POLICY';
export const ANALYTICS_PINWHEEL_OPEN_TERMS_OF_SERVICE =
    'ANALYTICS::PINWHEEL::OPEN_TERMS_OF_SERVICE';
export const ANALYTICS_PINWHEEL_FAILURE_CLICK_VIEW_INSTRUCTIONS =
    'ANALYTICS::PINWHEEL::CLICK_FAILURE_VIEW_INSTRUCTIONS';
export const ANALYTICS_PINWHEEL_ABORT_LANDING_CLICK_GO_TO_INSTRUCTIONS =
    'ANALYTICS::PINWHEEL::ABORT_LANDING_CLICK_GO_TO_INSTRUCTIONS';
export const ANALYTICS_PINWHEEL_CANNOT_FIND_PROVIDER_CLICK_GO_TO_INSTRUCTIONS =
    'ANALYTICS::PINWHEEL::CANNOT_FIND_PROVIDER_CLICK_GO_TO_INSTRUCTIONS';
export const ANALYTICS_PINWHEEL_CLICK_FINISHED_PAYROLL_INSTRUCTIONS =
    'ANALYTICS::PINWHEEL::CLICK_FINISHED_PAYROLL_INSTRUCTIONS';
export const ANALYTICS_PINWHEEL_OPEN_CANNOT_FIND_PAYROLL_PROVIDER =
    'ANALYTICS::PINWHEEL::OPEN_STEP::CANNOT_FIND_PAYROLL_PROVIDER';
export const ANALYTICS_PINWHEEL_CLICK_TRY_AGAIN =
    'ANALYTICS::PINWHEEL::CLICK_TRY_AGAIN';
export const ANALYTICS_PINWHEEL_CLICK_SUCCESS_INSTRUCTIONS =
    'ANALYTICS::PINWHEEL::CLICK_SUCCESS_INSTRUCTIONS';
export const ANALYTICS_PINWHEEL_CLICK_INSTRUCTIONS_COMPLETE_DONE =
    'ANALYTICS::PINWHEEL::CLICK_INSTRUCTIONS_COMPLETE_DONE';
export const ANALYTICS_PINWHEEL_CLICK_INSTRUCTIONS_COMPLETE_INSTRUCTIONS =
    'ANALYTICS::PINWHEEL::CLICK_INSTRUCTIONS_COMPLETE_INSTRUCTIONS';
export const ANALYTICS_PINWHEEL_OPEN_INSTANT_SHIP_SUCCESS =
    'ANALYTICS::PINWHEEL::OPEN_INSTANT_SHIP_SUCCESS';
export const ANALYTICS_PINWHEEL_CLICK_DONE_INSTANT_SHIP_SUCCESS =
    'ANALYTICS::PINWHEEL::CLICK_DONE_INSTANT_SHIP_SUCCESS';

export const ANALYTICS_PAYMENT_SETUP_OPEN_PAYMENT_SETUP_DETAILS =
    'ANALYTICS::PAYMENT_SETUP::OPEN_PAYMENT_SETUP_DETAILS';
export const ANALYTICS_PAYMENT_SETUP_CLICK_NEXT_PAYMENT_SETUP_DETAILS =
    'ANALYICS::PAYMENT_SETUP::CLICK_NEXT_PAYMENT_SETUP_DETAILS';
export const ANALYTICS_PAYMENT_SETUP_OPEN_PAYMENT_SETUP_DETAILS_CURRENT_DEDUCTION =
    'ANALYTICS::PAYMENT_SETUP::OPEN_PAYMENT_SETUP_DETAILS_RETURNING_BORROWER';

export const ANALYTICS_REFERRALS_CLICK_SHARE =
    'ANALYTICS::REFERRALS::CLICK_SHARE';
export const ANALYTICS_REFERRALS_CLICK_COPY =
    'ANALYTICS::REFERRALS::CLICK_COPY';
export const ANALYTICS_REFERRAL_LANDING_VIEWED =
    'ANALYTICS::REFERRAL_LANDING::VIEWED';

export const ANALYTICS_PERPAY_PLUS_CLICK_HAMBURGER =
    'ANALYTICS::PERPAY_PLUS::CLICK_HAMBURGER';

export const ANALYTICS_PERPAY_PLUS_VIEW_PAYS =
    'ANALYTICS::PERPAY_PLUS::PAYS::VIEW';
export const ANALYTICS_PERPAY_PLUS_CLICK_PAYS =
    'ANALYTICS::PERPAY_PLUS::PAYS::CLICK';
export const ANALYTICS_PERPAY_PLUS_COMPLETE_CLICK_CONTINUE =
    'ANALYTICS::PERPAY_PLUS::COMPLETE_CLICK_CONTINUE';

export const ANALYTICS_PERPAY_PLUS_INTRO_STEP_VIEWED =
    'ANALYTICS::PERPAY_PLUS::CARD_INTRO_STEP::VIEW';
export const ANALYTICS_PERPAY_PLUS_INTRO_CLICK_CLOSE =
    'ANALYTICS::PERPAY_PLUS::CARD_INTRO_STEP::CLICK_CLOSE';
export const ANALYTICS_PERPAY_PLUS_INTRO_CLICK_MAYBE_LATER =
    'ANALYTICS::PERPAY_PLUS::CARD_INTRO_STEP::CLICK_MAYBE_LATER';
export const ANALYTICS_PERPAY_PLUS_INTRO_CLICK_LETS_DO_IT =
    'ANALYTICS::PERPAY_PLUS::CARD_INTRO_STEP::CLICK_LETS_DO_IT';
export const ANALYTICS_PERPAY_PLUS_TERMS_MODAL_VIEW =
    'ANALYTICS::PERPAY_PLUS::TERMS_MODAL::VIEW';
export const ANALYTICS_PERPAY_PLUS_TERMS_MODAL_CLICK_ACTIVATE =
    'ANALYTICS::PERPAY_PLUS::TERMS_MODAL::CLICK_ACTIVATE';
export const ANALYTICS_PERPAY_PLUS_TERMS_MODAL_CLICK_CLOSE =
    'ANALYTICS::PERPAY_PLUS::TERMS_MODAL::CLICK_CLOSE';
export const ANALYTICS_PERPAY_PLUS_SUCCESS_STEP_VIEWED =
    'ANALYTICS::PERPAY_PLUS::SUCCESS_STEP::VIEW';
export const ANALYTICS_PERPAY_PLUS_SUCCESS_CLICK_DONE =
    'ANALYTICS::PERPAY_PLUS::SUCCESS_STEP::CLICK_DONE';

export const ANALYTICS_PAYMENT_SETUP_HELP_OPEN =
    'ANALYTICS::PAYMENT_SETUP_HELP::OPEN';
export const ANALYTICS_PAYMENT_SETUP_HELP_CLICK_CHAT =
    'ANALYTICS::PAYMENT_SETUP_HELP::CLICK_CHAT';
export const ANALYTICS_PAYMENT_SETUP_HELP_CLICK_PHONE =
    'ANALYTICS::PAYMENT_SETUP_HELP::CLICK_PHONE';
export const ANALYTICS_PAYMENT_SETUP_HELP_CLICK_BACK =
    'ANALYTICS::PAYMENT_SETUP_HELP::CLICK_BACK';

export const ANALYTICS_CREDIT_SCORE_DASHBOARD_CLICK_CARD_ACTIVATE_NOW =
    'ANALYTICS::CREDIT_SCORE::DASHBOARD::CLICK_CARD_ACTIVATE_NOW';
export const ANALYTICS_CREDIT_SCORE_DASHBOARD_CLICK_CARD =
    'ANALYTICS::CREDIT_SCORE::DASHBOARD::CLICK_CARD';
export const ANALYTICS_CREDIT_MONITORING_CLICK_FACTORS_DROPDOWN_V2 =
    'ANALYTICS::CREDIT_MONITORING::CLICK_FACTORS_V2_DROPDOWN';
export const ANALYTICS_CREDIT_MONITORING_EXPLAINER_MODAL_OPEN =
    'ANALYTICS::CREDIT_MONITORING::EXPLAINER::MODAL::OPEN';
export const ANALYTICS_CREDIT_MONITORING_EXPLAINER_MODAL_CLOSE =
    'ANALYTICS::CREDIT_MONITORING::EXPLAINER::MODAL::CLOSE';
export const ANALYTICS_CREDIT_MONITORING_DETAILS_CLICK_BACK_HEADER =
    'ANALYTICS::CREDIT_MONITORING_DETAILS::CLICK_CREDIT_BACK_HEADER';
export const ANALYTICS_CREDIT_MONITORING_CLICK_PRIOR_HISTORY_TABLE_PAGE =
    'ANALYTICS::CREDIT_MONITORING::CLICK_PRIOR_HISTORY_TABLE_PAGE';
export const ANALYTICS_NON_PINWHEEL_CLICK_SETUP_PAYMENT =
    'ANALYTICS::NON_PINWHEEL_PAYMENT_SETUP::CLICK_SETUP_PAYMENT';
export const ANALYTICS_NON_PINWHEEL_CLICK_PAYMENT_SETUP_COMPLETE_DONE =
    'ANALYTICS::NON_PINWHEEL_PAYMENT_SETUP::ON_CLICK_DONE';
export const ANALYTICS_NON_PINWHEEL_CLICK_PAYMENT_SETUP_COMPLETE_INSTRUCTIONS =
    'ANALYTICS::NON_PINWHEEL_PAYMENT_SETUP::ON_CLICK_INSTRUCTIONS';
export const ANALYTICS_NON_PINWHEEL_SET_UP_PAYMENTS_STEP_VIEWED =
    'ANALYTICS::NON_PINWHEEL_PAYMENT_SETUP::ON_STEP_VIEWED';
export const ANALYTICS_CREDIT_MONITORING_CLICK_CREDIT_HISTORY_COMPONENT =
    'ANALYTICS::CREDIT_MONITORING::CLICK_CREDIT_HISTORY_COMPONENT';

export const ANALYTICS_PERPAY_SPLIT_SET_UP_PAYMENTS_STEP_VIEWED =
    'ANALYTICS::PERPAY_SPLIT_SET_UP_PAYMENTS::STEP_VIEWED';
export const ANALYTICS_PERPAY_SPLIT_EMPLOYER_UPDATED =
    'ANALYTICS::PERPAY_SPLIT::EMPLOYER_UPDATED';

/* DASBOARD BOX AD */
export const ANALYTICS_EARLY_HOLIDAY_SHIP_BOX_AD_CLICK =
    'ANALYTICS::BOX_AD::EARLY_HOLIDAY_SHIP::CLICK';
export const ANALYTICS_CREDIT_LIMIT_INCREASE_ELIGIBLE_BOX_AD_CLICK =
    'ANALYTICS::BOX_AD::CREDIT_LIMIT_INCREASE_ELIGIBLE::CLICK';
export const ANALYTICS_CREDIT_LINE_BOX_AD_CLICK =
    'ANALYTICS::BOX_AD::CREDIT_LINE::CLICK';

/* MARKETPLACE DETAILS */
export const ANALYTICS_MARKETPLACE_DETAILS_PAGE_VIEW =
    'ANALYTICS::MARKETPLACE_DETAILS::PAGE_VIEW';
export const ANALYTICS_MARKETPLACE_DETAILS_CLICK_MORE_PAYMENTS =
    'ANALYTICS::MARKETPLACE_DETAILS::CLICK_MORE_PAYMENTS';

// ORDERS
export const ANALYTICS_MARKETPLACE_DETAILS_ALL_ORDERS =
    'ANALYTICS::DASHBOARD::ANALYTICS_MARKETPLACE_DETAILS_ALL_ORDERS';
export const ANALYTICS_MARKETPLACE_DETAILS_MORE_ORDERS =
    'ANALYTICS::ANALYTICS_MARKETPLACE_DETAILS_MORE_ORDERS';

// MARKETPLACE SEARCH
export const ANALYTICS_MARKETPLACE_DETAILS_CLICK_SEARCH =
    'ANALYTICS::MARKETPLACE_DETAILS::CLICK_SEARCH';
export const ANALYTICS_MARKETPLACE_DETAILS_CLICK_SHOP_CATEGORY =
    'ANALYTICS::MARKETPLACE_DETAILS::CLICK_SHOP_CATEGORY';
export const ANALYTICS_MARKETPLACE_DETAILS_CATEGORIES_SCROLL =
    'ANALYTICS::MARKETPLACE_DETAILS::CATEGORIES_SCROLL';

// MARKETPLACE LEARN (EMPTY STATE)
export const ANALYTICS_MARKETPLACE_LEARN_CLICK_SHOP_NOW =
    'ANALYTICS::MARKETPLACE_LEARN::CLICK_SHOP_NOW';
export const ANALYTICS_MARKETPLACE_LEARN_SWIPE_SLIDE =
    'ANALYTICS::MARKETPLACE_LEARN::SWIPE_SLIDE';
export const ANALYTICS_MARKETPLACE_LEARN_PAGE_VIEW =
    'ANALYTICS::MARKETPLACE_LEARN::PAGE_VIEW';

/* PERPAY CREDIT CARD */
export const STORE_REPLACE_CARD_ONBOARDING_MVP_2_AMOUNT_TO_CHARGE =
    'STORE::CARD_ONBOARDING_MVP_2_AMOUNT_TO_CHARGE::REPLACE';

// TAKEOVER
export const ANALYTICS_CARD_TAKEOVER_CLICK_CONTINUE =
    'ANALYTICS::CARD::TAKEOVER::CLICK_CONTINUE';
export const ANALYTICS_CARD_TAKEOVER_CLICK_CLOSE =
    'ANALYTICS::CARD::TAKEOVER::CLICK_CLOSE';
export const ANALYTICS_CARD_TAKEOVER_CLICK_LEARN_MORE =
    'ANALYTICS::CARD::TAKEOVER::CLICK_LEARN_MORE';

// ONBOARDING
export const ANALYTICS_CARD_ONBOARDING_STEP_VIEWED =
    'ANALYTICS::CARD_ONBOARDING::STEP_VIEWED';

export const ANALYTICS_CARD_ONBOARDING_TERMS_CLICK_EDIT =
    'ANALYTICS::CARD_ONBOARDING::TERMS::CLICK_EDIT';
export const ANALYTICS_CARD_ONBOARDING_TERMS_CLICK_CONTINUE =
    'ANALYTICS::CARD_ONBOARDING::TERMS::CLICK_CONTINUE';
export const ANALYTICS_CARD_ONBOARDING_TERMS_OPEN_AUTO_TRANSFER_MODAL =
    'ANALYTICS::CARD_ONBOARDING::TERMS_OPEN_AUTO_TRANSFER_MODAL';
export const ANALYTICS_CARD_ONBOARDING_TERMS_CLICK_TRANSFERS_AGREEMENT =
    'ANALYTICS::CARD_ONBOARDING::TERMS::CLICK_TRANSFERS_AGREEMENT';
export const ANALYTICS_CARD_ONBOARDING_TERMS_CLICK_CARD_HOLDER_AGREEMENT =
    'ANALYTICS::CARD_ONBOARDING::TERMS::CLICK_CARD_HOLDER_AGREEMENT';
export const ANALYTICS_CARD_ONBOARDING_TERMS_CLICK_REWARDS =
    'ANALYTICS::CARD_ONBOARDING::TERMS::CLICK_REWARDS';
export const ANALYTICS_CARD_ONBOARDING_TERMS_CLICK_DIRECT_DEPOSIT =
    'ANALYTICS::CARD_ONBOARDING::TERMS::CLICK_DIRECT_DEPOSIT';
export const ANALYTICS_CARD_ONBOARDING_TERMS_CLICK_CONSENT_POLICY =
    'ANALYTICS::CARD_ONBOARDING::TERMS::CLICK_CONSENT_POLICY';
export const ANALYTICS_CARD_ONBOARDING_TERMS_CLICK_PRIVACY_POLICY =
    'ANALYTICS::CARD_ONBOARDING::TERMS::CLICK_PRIVACY_POLICY';
export const ANALYTICS_CARD_ONBOARDING_TERMS_CLICK_PRIVACY_NOTICE =
    'ANALYTICS::CARD_ONBOARDING::TERMS::CLICK_PRIVACY_NOTICE';
export const ANALYTICS_CARD_ONBOARDING_TERMS_CLICK_PATRIOT_ACT =
    'ANALYTICS::CARD_ONBOARDING::TERMS::CLICK_PATRIOT_ACT';
export const ANALYTICS_CARD_ONBOARDING_TERMS_CLICK_BACK_HEADER =
    'ANALYTICS::CARD_ONBOARDING::TERMS::CLICK_BACK_HEADER';

export const ANALYTICS_CARD_ONBOARDING_MVP_2_TERMS_CLICK_EDIT =
    'ANALYTICS::CARD_ONBOARDING_MVP_2::TERMS::CLICK_EDIT';
export const ANALYTICS_CARD_ONBOARDING_MVP_2_TERMS_CLICK_CONTINUE =
    'ANALYTICS::CARD_ONBOARDING_MVP_2::TERMS::CLICK_CONTINUE';
export const ANALYTICS_CARD_ONBOARDING_MVP_2_TERMS_OPEN_AUTO_TRANSFER_MODAL =
    'ANALYTICS::CARD_ONBOARDING_MVP_2::TERMS::OPEN_AUTO_TRANSFER_MODAL';
export const ANALYTICS_CARD_ONBOARDING_MVP_2_TERMS_CLICK_TRANSFERS_AGREEMENT =
    'ANALYTICS::CARD_ONBOARDING_MVP_2::TERMS::CLICK_TRANSFERS_AGREEMENT';
export const ANALYTICS_CARD_ONBOARDING_MVP_2_TERMS_CLICK_CARD_HOLDER_AGREEMENT =
    'ANALYTICS::CARD_ONBOARDING_MVP_2::TERMS::CLICK_CARD_HOLDER_AGREEMENT';
export const ANALYTICS_CARD_ONBOARDING_MVP_2_TERMS_CLICK_REWARDS =
    'ANALYTICS::CARD_ONBOARDING_MVP_2::TERMS::CLICK_REWARDS';
export const ANALYTICS_CARD_ONBOARDING_MVP_2_TERMS_CLICK_DIRECT_DEPOSIT =
    'ANALYTICS::CARD_ONBOARDING_MVP_2::TERMS::CLICK_DIRECT_DEPOSIT';
export const ANALYTICS_CARD_ONBOARDING_MVP_2_TERMS_CLICK_CONSENT_POLICY =
    'ANALYTICS::CARD_ONBOARDING_MVP_2::TERMS::CLICK_CONSENT_POLICY';
export const ANALYTICS_CARD_ONBOARDING_MVP_2_TERMS_CLICK_PRIVACY_POLICY =
    'ANALYTICS::CARD_ONBOARDING_MVP_2::TERMS::CLICK_PRIVACY_POLICY';
export const ANALYTICS_CARD_ONBOARDING_MVP_2_TERMS_CLICK_PRIVACY_NOTICE =
    'ANALYTICS::CARD_ONBOARDING_MVP_2::TERMS::CLICK_PRIVACY_NOTICE';
export const ANALYTICS_CARD_ONBOARDING_MVP_2_TERMS_CLICK_PATRIOT_ACT =
    'ANALYTICS::CARD_ONBOARDING_MVP_2::TERMS::CLICK_PATRIOT_ACT';
export const ANALYTICS_CARD_ONBOARDING_MVP_2_TERMS_CLICK_BACK_HEADER =
    'ANALYTICS::CARD_ONBOARDING_MVP_2::TERMS::CLICK_BACK_HEADER';

export const ANALYTICS_CARD_ONBOARDING_ADDRESS_CLICK_DONE =
    'ANALYTICS::CARD_ONBOARDING::ADDRESS::CLICK_DONE';
export const ANALYTICS_CARD_ONBOARDING_ADDRESS_BACK_HEADER =
    'ANALYTICS::CARD_ONBOARDING::ADDRESS::BACK_HEADER';

export const ANALYTICS_CARD_ONBOARDING_APPROVED_CLICK_NOT_NOW =
    'ANALYTICS::CARD_ONBOARDING::APPROVED::CLICK_NOT_NOW';
export const ANALYTICS_CARD_ONBOARDING_APPROVED_CLICK_ACCEPT_TERMS =
    'ANALYTICS::CARD_ONBOARDING::APPROVED::CLICK_ACCEPT_TERMS';
export const ANALYTICS_CARD_ONBOARDING_APPROVED_CLICK_DASHBOARD_HEADER =
    'ANALYTICS::CARD_ONBOARDING::APPROVED::CLICK_DASHBOARD_HEADER';

export const ANALYTICS_CARD_ONBOARDING_PAYMENT_SUMMARY_CLICK_CONTINUE =
    'ANALYTICS::CARD_ONBOARDING::PAYMENT_SUMMARY::CLICK_CONTINUE';
export const ANALYTICS_CARD_ONBOARDING_PAYMENT_SUMMARY_CLICK_DASHBOARD_HEADER =
    'ANALYTICS::CARD_ONBOARDING::PAYMENT_SUMMARY::CLICK_BACK_HEADER';

export const ANALYTICS_CARD_ONBOARDING_OPEN_PINWHEEL_MODAL =
    'ANALYTICS::CARD_ONBOARDING::OPEN_PINWHEEL_MODAL';

export const ANALYTICS_CARD_ONBOARDING_MANUAL_SETUP_TRY_AGAIN_CLICK =
    'ANALYTICS::CARD_ONBOARDING::MANUAL_SETUP::TRY_AGAIN_CLICK';
export const ANALYTICS_CARD_ONBOARDING_MANUAL_SETUP_DONE_CLICK =
    'ANALYTICS::CARD_ONBOARDING::MANUAL_SETUP::DONE_CLICK';
export const ANALYTICS_CARD_ONBOARDING_MANUAL_SETUP_CLICK_DASHBOARD_HEADER =
    'ANALYTICS::CARD_ONBOARDING::MANUAL_SETUP::CLICK_DASHBOARD_HEADER';
export const ANALYTICS_CARD_ONBOARDING_MANUAL_SETUP_FORM_CLICK =
    'ANALYTICS::CARD_ONBOARDING::MANUAL_SETUP::FORM_CLICK';
export const ANALYTICS_CARD_ONBOARDING_MANUAL_SETUP_INSTRUCTIONS_CLICK =
    'ANALYTICS::CARD_ONBOARDING::MANUAL_SETUP::INSTRUCTIONS_CLICK';
export const ANALYTICS_CARD_ONBOARDING_MANUAL_SETUP_PAGE_VIEW =
    'ANALYTICS::CARD_ONBOARDING::MANUAL_SETUP::PAGE_VIEW';

export const ANALYTICS_CARD_ONBOARDING_PINWHEEL_FAILURE_TRY_AGAIN_CLICK =
    'ANALYTICS::CARD_ONBOARDING::PINWHEEL_FAILURE::TRY_AGAIN_CLICK';
export const ANALYTICS_CARD_ONBOARDING_PINWHEEL_FAILURE_DONE_CLICK =
    'ANALYTICS::CARD_ONBOARDING::PINWHEEL_FAILURE::DONE_CLICK';
export const ANALYTICS_CARD_ONBOARDING_PINWHEEL_FAILURE_CLICK_DASHBOARD_HEADER =
    'ANALYTICS::CARD_ONBOARDING::PINWHEEL_FAILURE::CLICK_DASHBOARD_HEADER';
export const ANALYTICS_CARD_ONBOARDING_PINWHEEL_FAILURE_FORM_CLICK =
    'ANALYTICS::CARD_ONBOARDING::PINWHEEL_FAILURE::FORM_CLICK';
export const ANALYTICS_CARD_ONBOARDING_PINWHEEL_FAILURE_INSTRUCTIONS_CLICK =
    'ANALYTICS::CARD_ONBOARDING::PINWHEEL_FAILURE::INSTRUCTIONS_CLICK';
export const ANALYTICS_CARD_ONBOARDING_PINWHEEL_FAILURE_PAGE_VIEW =
    'ANALYTICS::CARD_ONBOARDING::PINWHEEL_FAILURE::PAGE_VIEW';

export const ANALYTICS_CARD_ONBOARDING_ACCOUNT_CREATION_SUCCESSFUL_CLICK_DASHBOARD_FOOTER =
    'ANALYTICS::CARD_ONBOARDING::ACCOUNT_CREATION_SUCCESSFUL::CLICK_DASHBOARD_FOOTER';
export const ANALYTICS_CARD_ONBOARDING_ACCOUNT_CREATION_SUCCESSFUL_CLICK_DASHBOARD_HEADER =
    'ANALYTICS::CARD_ONBOARDING::ACCOUNT_CREATION_SUCCESSFUL::CLICK_DASHBOARD_HEADER';

export const ANALYTICS_CARD_ONBOARDING_REJECTED_CLICK_SHOP =
    'ANALYTICS::CARD_ONBOARDING::REJECTED::CLICK_SHOP';
export const ANALYTICS_CARD_ONBOARDING_REJECTED_CLICK_DASHBOARD_FOOTER =
    'ANALYTICS::CARD_ONBOARDING::REJECTED::CLICK_DASHBOARD_FOOTER';
export const ANALYTICS_CARD_ONBOARDING_REJECTED_CLICK_DASHBOARD_HEADER =
    'ANALYTICS::CARD_ONBOARDING::REJECTED::CLICK_DASHBOARD_HEADER';

export const ANALYTICS_CARD_ONBOARDING_ERROR_CLICK_DASHBOARD_HEADER =
    'ANALYTICS::CARD_ONBOARDING::ERROR::CLICK_DASHBOARD_HEADER';
export const ANALYTICS_CARD_ONBOARDING_ERROR_CLICK_DONE =
    'ANALYTICS::CARD_ONBOARDING::ERROR::CLICK_DONE';

export const ANALYTICS_CARD_ONBOARDING_UNFREEZE_CLICK_DASHBOARD_HEADER =
    'ANALYTICS::CARD_ONBOARDING::UNFREEZE::CLICK_DASHBOARD_HEADER';
export const ANALYTICS_CARD_ONBOARDING_UNFREEZE_CLICK_DONE =
    'ANALYTICS::CARD_ONBOARDING::UNFREEZE::CLICK_DONE';
export const ANALYTICS_CARD_ONBOARDING_UNFREEZE_CLICK_TRY_AGAIN =
    'ANALYTICS::CARD_ONBOARDING::UNFREEZE::CLICK_TRY_AGAIN';

export const ANALYTICS_CARD_ONBOARDING_UPLOAD_DOCS_CLICK_DASHBOARD_HEADER =
    'ANALYTICS::CARD_ONBOARDING::UPLOAD_DOCS::CLICK_DASHBOARD_HEADER';
export const ANALYTICS_CARD_ONBOARDING_UPLOAD_DOCS_CLICK_SUBMIT =
    'ANALYTICS::CARD_ONBOARDING::UPLOAD_DOCS::CLICK_SUBMIT';
export const ANALYTICS_CARD_ONBOARDING_UPLOAD_DOCS_CLICK_UPLOAD =
    'ANALYTICS::CARD_ONBOARDING::UPLOAD_DOCS::CLICK_UPLOAD';

export const ANALYTICS_CARD_ONBOARDING_IN_REVIEW_CLICK_DASHBOARD_HEADER =
    'ANALYTICS::CARD_ONBOARDING::IN_REVIEW::CLICK_DASHBOARD_HEADER';
export const ANALYTICS_CARD_ONBOARDING_IN_REVIEW_CLICK_DASHBOARD_FOOTER =
    'ANALYTICS::CARD_ONBOARDING::IN_REVIEW::CLICK_DASHBOARD_FOOTER';

export const ANALYTICS_CARD_ONBOARDING_SELECT_MATERIAL_CLICK_HEAVY_METAL =
    'ANALYTICS::CARD_ONBOARDING::SELECT_MATERIAL::CLICK_HEAVY_METAL';
export const ANALYTICS_CARD_ONBOARDING_SELECT_MATERIAL_CLICK_LIGHT_PLASTIC =
    'ANALYTICS::CARD_ONBOARDING::SELECT_MATERIAL::CLICK_LIGHT_PLASTIC';
export const ANALYTICS_CARD_ONBOARDING_SELECT_MATERIAL_CLICK_SUBMIT_BUTTON =
    'ANALYTICS::CARD_ONBOARDING::SELECT_MATERIAL::CLICK_SUBMIT_BUTTON';
// MVP 2 FTB CARD ONBOARDING
export const ANALYTICS_CARD_ONBOARDING_MVP_2_STEP_VIEWED =
    'ANALYTICS::CARD_ONBOARDING_MVP_2::STEP_VIEWED';

export const ANALYTICS_CARD_ONBOARDING_MVP_2_OPEN_PINWHEEL_MODAL =
    'ANALYTICS::CARD_ONBOARDING_MVP2::OPEN_PINWHEEL_MODAL';

// MVP 2 TAKEOVER
export const ANALYTICS_CARD_TAKEOVER_MVP_2_CLICK_CONTINUE =
    'ANALYTICS::CARD::TAKEOVER_MVP_2::CLICK_CONTINUE';
export const ANALYTICS_CARD_TAKEOVER_MVP_2_CLICK_CLOSE =
    'ANALYTICS::CARD::TAKEOVER_MVP_2::CLICK_CLOSE';
export const ANALYTICS_CARD_TAKEOVER_MVP_2_CLICK_LEARN_MORE =
    'ANALYTICS::CARD::TAKEOVER_MVP_2::CLICK_LEARN_MORE';

// MVP 2 EMPLOYMENT
export const ANALYTICS_CARD_CONFIRM_EMPLOYMENT_MVP_2_CLICK_NEXT =
    'ANALYTICS::CARD::CONFIRM_EMPLOYMENT_MVP_2::CLICK_NEXT';
export const ANALYTICS_CARD_CONFIRM_EMPLOYMENT_MVP_2_CLICK_EDIT =
    'ANALYTICS::CARD::CONFIRM_EMPLOYMENT_MVP_2::CLICK_EDIT';
export const ANALYTICS_CARD_CONFIRM_EMPLOYMENT_MVP_2_CLICK_BACK =
    'ANALYTICS::CARD::CONFIRM_EMPLOYMENT_MVP_2::CLICK_BACK';
export const ANALYTICS_CARD_EDIT_EMPLOYMENT_MVP_2_CLICK_BACK =
    'ANALYTICS::CARD::EDIT_EMPLOYMENT_MVP_2::CLICK_BACK';
export const ANALYTICS_CARD_EDIT_EMPLOYMENT_MVP_2_CLICK_SAVE =
    'ANALYTICS::CARD::EDIT_EMPLOYMENT_MVP_2::CLICK_SAVE';
export const ANALYTICS_CARD_EDIT_EMPLOYMENT_MVP_2_CLICK_ADD_COMPANY =
    'ANALYTICS::CARD::EDIT_EMPLOYMENT_MVP_2::CLICK_ADD_COMPANY';

// MVP 2 VERIFY IDENTITY
export const ANALYTICS_CARD_VERIFY_IDENTITY_MVP_2_CLICK_BACK =
    'ANALYTICS::CARD::VERIFY_IDENTITY_MVP_2::CLICK_BACK';
export const ANALYTICS_CARD_VERIFY_IDENTITY_MVP_2_CLICK_NEXT =
    'ANALYTICS::CARD::VERIFY_IDENTITY_MVP_2::CLICK_NEXT';

// MVP 2 PAYMENT SETUP SUMMARY
export const ANALYTICS_CARD_PAYMENT_SUMMARY_MVP_2_CLICK_CONTINUE =
    'ANALYTICS::CARD::PAYMENT_SETUP_SUMMARY_MVP_2::CLICK_CONTINUE';
export const ANALYTICS_CARD_PAYMENT_SUMMARY_MVP_2_CLICK_DASHBOARD =
    'ANALYTICS::CARD::PAYMENT_SETUP_SUMMARY_MVP_2::CLICK_DASHBOARD';
export const ANALYTICS_CARD_PAYMENT_SUMMARY_MVP_2_CLICK_LEARN_MORE =
    'ANALYTICS::CARD::PAYMENT_SETUP_SUMMARY_MVP_2::CLICK_LEARN_MORE';
export const ANALYTICS_CARD_PAYMENT_SUMMARY_MVP_2_VIEW_DD_MODAL =
    'ANALYTICS::CARD::PAYMENT_SETUP_SUMMARY_MVP_2::VIEW_DD_MODAL';
export const ANALYTICS_CARD_PAYMENT_SUMMARY_MVP_2_CLOSE_DD_MODAL =
    'ANALYTICS::CARD::PAYMENT_SETUP_SUMMARY_MVP_2::CLOSE_DD_MODAL';

// MVP 2 SELECT MINIMUM PAYMENT
export const ANALYTICS_CARD_SELECT_MINIMUM_PAYMENT_MVP_2_CLICK_SUBMIT =
    'ANALYTICS::CARD::SELECT_MINIMUM_PAYMENT_MVP_2::CLICK_SUBMIT';
export const ANALYTICS_CARD_SELECT_MINIMUM_PAYMENT_MVP_2_CLICK_LEARN_MORE =
    'ANALYTICS::CARD::SELECT_MINIMUM_PAYMENT_MVP_2::CLICK_LEARN_MORE';
export const ANALYTICS_CARD_SELECT_MINIMUM_PAYMENT_MVP_2_VIEW_DD_MODAL =
    'ANALYTICS::CARD::SELECT_MINIMUM_PAYMENT_MVP_2::VIEW_DD_MODAL';
export const ANALYTICS_CARD_SELECT_MINIMUM_PAYMENT_MVP_2_CLOSE_DD_MODAL =
    'ANALYTICS::CARD::SELECT_MINIMUM_PAYMENT_MVP_2::CLOSE_DD_MODAL';

// MVP 2 DEDUCTION SUCCESS
export const ANALYTICS_CARD_ONBOARDING_ACCOUNT_CREATION_SUCCESSFUL_MVP_2_CLICK_DASHBOARD =
    'ANALYTICS::CARD::DEDUCTION_SUCCESS_MVP_2::CLICK_DASHBOARD';

// MVP 2 CARD APPLICATION REJECTED
export const ANALYTICS_CARD_MVP_2_REJECTED_CLICK_SHOP =
    'ANALYTICS::CARD_ONBOARDING_MVP_2::REJECTED::CLICK_SHOP';
export const ANALYTICS_CARD_MVP_2_REJECTED_CLICK_DASHBOARD_FOOTER =
    'ANALYTICS::CARD_ONBOARDING_MVP_2::REJECTED::CLICK_DASHBOARD_FOOTER';
export const ANALYTICS_CARD_MVP_2_REJECTED_CLICK_DASHBOARD_HEADER =
    'ANALYTICS::CARD_ONBOARDING_MVP_2::REJECTED::CLICK_DASHBOARD_HEADER';

// MVP 2 CARD APPLICATION ERROR
export const ANALYTICS_CARD_MVP_2_ERROR_CLICK_DASHBOARD =
    'ANALYTICS::CARD_ONBOARDING_MVP_2::ERROR::CLICK_DASHBOARD_HEADER';
export const ANALYTICS_CARD_MVP_2_ERROR_CLICK_DONE =
    'ANALYTICS::CARD_ONBOARDING_MVP_2::ERROR::CLICK_DONE';

// MVP 2 CARD MANUAL SETUP
export const ANALYTICS_CARD_MANUAL_SETUP_MVP_2_CLICK_DASHBOARD =
    'ANALYTICS::CARD::MANUAL_SETUP_MVP_2::CLICK_DASHBOARD';
export const ANALYTICS_CARD_MANUAL_SETUP_MVP_2_CLICK_FORM =
    'ANALYTICS::CARD::MANUAL_SETUP_MVP_2::CLICK_FORM';
export const ANALYTICS_CARD_MANUAL_SETUP_MVP_2_CLICK_INSTRUCTIONS =
    'ANALYTICS::CARD::MANUAL_SETUP_MVP_2::CLICK_INSTRUCTIONS';
export const ANALYTICS_CARD_MANUAL_SETUP_MVP_2_CLICK_DONE =
    'ANALYTICS::CARD::MANUAL_SETUP_MVP_2::CLICK_DONE';
export const ANALYTICS_CARD_MANUAL_SETUP_MVP_2_CLICK_TRY_AGAIN =
    'ANALYTICS::CARD::MANUAL_SETUP_MVP_2::CLICK_TRY_AGAIN';
export const ANALYTICS_CARD_MANUAL_SETUP_MVP_2_PAGE_VIEW =
    'ANALYTICS::CARD::MANUAL_SETUP_MVP_2::PAGE_VIEW';

// MVP 2 CARD PINWHEEL FAILURE
export const ANALYTICS_CARD_PINWHEEL_FAILURE_MVP_2_CLICK_DASHBOARD =
    'ANALYTICS::CARD::PINWHEEL_FAILURE_MVP_2::CLICK_DASHBOARD';
export const ANALYTICS_CARD_PINWHEEL_FAILURE_MVP_2_CLICK_FORM =
    'ANALYTICS::CARD::PINWHEEL_FAILURE_MVP_2::CLICK_FORM';
export const ANALYTICS_CARD_PINWHEEL_FAILURE_MVP_2_CLICK_INSTRUCTIONS =
    'ANALYTICS::CARD::PINWHEEL_FAILURE_MVP_2::CLICK_INSTRUCTIONS';
export const ANALYTICS_CARD_PINWHEEL_FAILURE_MVP_2_CLICK_DONE =
    'ANALYTICS::CARD::PINWHEEL_FAILURE_MVP_2::CLICK_DONE';
export const ANALYTICS_CARD_PINWHEEL_FAILURE_MVP_2_CLICK_TRY_AGAIN =
    'ANALYTICS::CARD::PINWHEEL_FAILURE_MVP_2::CLICK_TRY_AGAIN';
export const ANALYTICS_CARD_PINWHEEL_FAILURE_MVP_2_PAGE_VIEW =
    'ANALYTICS::CARD::PINWHEEL_FAILURE_MVP_2::PAGE_VIEW';

// MVP 2 ADD ADDRESS
export const ANALYTICS_CARD_ADD_ADDRESS_MVP_2_SUBMIT_ADDRESS_VALIDATION =
    'ANALYTICS::CARD::ADD_ADDRESS_MVP_2::SUBMIT_ADDRESS_VALIDATION';
export const ANALYTICS_CARD_ADD_ADDRESS_MVP_2_SUBMIT_CHOSEN_ADDRESS =
    'ANALYTICS::CARD::ADD_ADDRESS_MVP_2::SUBMIT_CHOSEN_ADDRESS';
export const ANALYTICS_CARD_ADD_ADDRESS_MVP_2_CLICK_BACK =
    'ANALYTICS::CARD::ADD_ADDRESS_MVP_2::CLICK_BACK';

// MVP 2 ADDRESS REJECTED
export const ANALYTICS_CARD_ADDRESS_REJECTED_MVP_2_PAGE_VIEW =
    'ANALYTICS::CARD::ADDRESS_REJECTED_MVP_2::PAGE_VIEW';
export const ANALYTICS_CARD_ADDRESS_REJECTED_MVP_2_CLICK_BACK =
    'ANALYTICS::CARD::ADDRESS_REJECTED_MVP_2::CLICK_BACK';
export const ANALYTICS_CARD_ADDRESS_REJECTED_MVP_2_CLICK_EXPLORE_MARKETPLACE =
    'ANALYTICS::CARD::ADDRESS_REJECTED_MVP_2::EXPLORE_MARKETPLACE';

// MVP 2 CREDIT UNFREEZE
export const ANALYTICS_CARD_ONBOARDING_UNFREEZE_MVP_2_CLICK_DONE =
    'ANALYTICS::CARD::CREDIT_UNFREEZE_MVP_2::CLICK_DONE';
export const ANALYTICS_CARD_ONBOARDING_UNFREEZE_MVP_2_CLICK_TRY_AGAIN =
    'ANALYTICS::CARD::CREDIT_UNFREEZE_MVP_2::CLICK_TRY_AGAIN';
export const ANALYTICS_CARD_ONBOARDING_UNFREEZE_MVP_2_CLICK_DASHBOARD_HEADER =
    'ANALYTICS::CARD::CREDIT_UNFREEZE_MVP_2::CLICK_DASHBOARD_HEADER';

// MVP 2 CARD APPLICATION EMPLOYMENT REJECTED
export const ANALYTICS_CARD_MVP_2_EMPLOYMENT_REJECTED_CLICK_BACK =
    'ANALYTICS::CARD_ONBOARDING_MVP_2::EMPLOYMENT_REJECTED::CLICK_BANK';
export const ANALYTICS_CARD_MVP_2_EMPLOYMENT_REJECTED_CLICK_DASHBOARD_FOOTER =
    'ANALYTICS::CARD_ONBOARDING_MVP_2::EMPLOYMENT_REJECTED::CLICK_DASHBOARD_FOOTER';
export const ANALYTICS_CARD_MVP_2_EMPLOYMENT_REJECTED_CLICK_DASHBOARD_HEADER =
    'ANALYTICS::CARD_ONBOARDING_MVP_2::EMPLOYMENT_REJECTED::CLICK_DASHBOARD_HEADER';

// MVP 2 APPROVED
export const ANALYTICS_CARD_APPROVED_MVP_2_CLICK_ACCEPT =
    'ANALYTICS::CARD::APPROVED_MVP_2::CLICK_ACCEPT';
export const ANALYTICS_CARD_APPROVED_MVP_2_CLICK_NOT_NOW =
    'ANALYTICS::CARD::APPROVED_MVP_2::CLICK_NOT_NOW';

// MVP 2 UPLOAD DOCUMENTS
export const ANALYTICS_CARD_MVP_2_UPLOAD_DOCS_CLICK_DASHBOARD_HEADER =
    'ANALYTICS::CARD_ONBOARDING_MVP_2::UPLOAD_DOCS::CLICK_DASHBOARD_HEADER';
export const ANALYTICS_CARD_MVP_2_UPLOAD_DOCS_CLICK_SUBMIT =
    'ANALYTICS::CARD_ONBOARDING_MVP_2::UPLOAD_DOCS::CLICK_SUBMIT';
export const ANALYTICS_CARD_MVP_2_UPLOAD_DOCS_CLICK_UPLOAD =
    'ANALYTICS::CARD_ONBOARDING_MVP_2::UPLOAD_DOCS::CLICK_UPLOAD';

// MVP 2 UPLOAD DOCUMENTS SUCCESS / IN_REVIEW
export const ANALYTICS_CARD_MVP_2_IN_REVIEW_CLICK_DASHBOARD_HEADER =
    'ANALYTICS::CARD_ONBOARDING_MVP_2::IN_REVIEW::CLICK_DASHBOARD_HEADER';
export const ANALYTICS_CARD_MVP_2_IN_REVIEW_CLICK_DASHBOARD_FOOTER =
    'ANALYTICS::CARD_ONBOARDING_MVP_2::IN_REVIEW::CLICK_DASHBOARD_FOOTER';

// PERPAY CARD ACTIVATION FLOW
export const ANALYTICS_CARD_ACTIVATION_STEP_VIEWED =
    'ANALYTICS::CARD_ACTIVATION::STEP_VIEWED';
export const ANALYTICS_CARD_ACTIVATION_SUBMIT_LAST_FOUR =
    'ANALYTICS::CARD_ACTIVATION::SUBMIT_LAST_FOUR';
export const ANALYTICS_CARD_ACTIVATION_SLIDER_CLICK_NEXT =
    'ANALYTICS::CARD_ACTIVATION_SLIDER::CLICK_DONE';
export const ANALYTICS_CARD_ACTIVATION_SLIDER_CLICK_DONE =
    'ANALYTICS::CARD_ACTIVATION_SLIDER::CLICK_DONE';
export const ANALYTICS_CARD_ACTIVATION_SLIDER_STEP_VIEWED =
    'ANALYTICS::CARD_ACTIVATION_SLIDER::STEP_VIEWED';

// PERPAY CARD REWARDS & CREDITS PAGE
export const ANALYTICS_CARD_REWARDS_AND_CREDITS_PAGE_VIEW =
    'ANALYTICS::CARD_REWARDS_AND_CREDITS::PAGE_VIEW';
export const ANALYTICS_CARD_REWARDS_AND_CREDITS_CLICK_SHOP =
    'ANALYTICS::CARD_REWARDS_AND_CREDITS::CLICK_SHOP';
export const ANALYTICS_CARD_REWARDS_AND_CREDITS_CLICK_LEARN_MORE =
    'ANALYTICS::CARD_REWARDS_AND_CREDITS::CLICK_LEARN_MORE';
export const ANALYTICS_CARD_REWARDS_AND_CREDITS_CLICK_SEARCH =
    'ANALYTICS::CARD_REWARDS_AND_CREDITS::CLICK_SEARCH';
export const ANALYTICS_CARD_REWARDS_CLICK_SHOP_CATEGORY_1 =
    'ANALYTICS::CARD_REWARDS_AND_CREDITS_CLICK_SHOP_CATEGORY_1';
export const ANALYTICS_CARD_REWARDS_CLICK_SHOP_CATEGORY_2 =
    'ANALYTICS::CARD_REWARDS_AND_CREDITS_CLICK_SHOP_CATEGORY_2';
export const ANALYTICS_CARD_REWARDS_CLICK_SHOP_CATEGORY_3 =
    'ANALYTICS::CARD_REWARDS_AND_CREDITS_CLICK_SHOP_CATEGORY_3';
export const ANALYTICS_CARD_REWARDS_CLICK_SHOP_CATEGORY_4 =
    'ANALYTICS::CARD_REWARDS_AND_CREDITS_CLICK_SHOP_CATEGORY_4';

export const ANALYTICS_REWARDS_AND_CREDITS_V2_EXPLAINER_MODAL_OPEN =
    'ANALYTICS::ANALYTICS_REWARDS_AND_CREDITS_V2::EXPLAINER_MODAL_OPEN';
export const ANALYTICS_REWARDS_AND_CREDITS_V2_EXPLAINER_MODAL_CLOSE =
    'ANALYTICS::ANALYTICS_REWARDS_AND_CREDITS_V2::EXPLAINER_MODAL_CLOSE';
export const ANALYTICS_REWARDS_AND_CREDITS_V2_CLICK_CREDIT_SUMMARY =
    'ANALYTICS::ANALYTICS_REWARDS_AND_CREDITS_V2::CLICK_CREDIT_SUMMARY';
export const ANALYTICS_REWARDS_AND_CREDITS_V2_CLICK_REWARD_SUMMARY =
    'ANALYTICS::ANALYTICS_REWARDS_AND_CREDITS_V2::CLICK_REWARD_SUMMARY';
export const ANALYTICS_REWARDS_AND_CREDITS_V2_CLICK_SHOP_CATEGORY =
    'ANALYTICS::REWARDS_AND_CREDITS_V2::CLICK_SHOP_CATEGORY';
export const ANALYTICS_REWARDS_AND_CREDITS_V2_CLICK_SEARCH =
    'ANALYTICS::REWARDS_AND_CREDITS_V2::CLICK_SEARCH';
export const ANALYTICS_REWARDS_AND_CREDITS_V2_CATEGORIES_SCROLL =
    'ANALYTICS::REWARDS_AND_CREDITS_V2:CATEGORIES_SCROLL';
export const ANALYTICS_REWARDS_AND_CREDITS_V2_CLICK_SHOP =
    'ANALYTICS::REWARDS_AND_CREDITS_V2::CLICK_SHOP';
export const ANALYTICS_REWARDS_AND_CREDITS_V2_CLICK_LEARN_MORE =
    'ANALYTICS::REWARDS_AND_CREDITS_V2::CLICK_LEARN_MORE';
export const ANALYTICS_REWARDS_AND_CREDITS_V2_CLICK_HEADER_BACK =
    'ANALYTICS::REWARDS_AND_CREDITS_V2::CLICK_HEADER_BACK';

// PERPAY CARD REWARDS SUMMARY COMPONENT
export const ANALYTICS_CARD_REWARDS_SUMMARY_CLICK_CARD =
    'ANALYTICS::CARD_REWARDS_SUMMARY_COMPONENT::CLICK_CARD';

// PERPAY CARD REWARDS DETAILS PAGE
export const ANALYTICS_CARD_REWARDS_AND_CREDITS_CLICK_DETAILS =
    'ANALYTICS::CARD_REWARDS_AND_CREDITS::CLICK_DETAILS';
export const ANALYTICS_CARD_REWARDS_AND_CREDITS_DETAILS_PAGE_VIEW =
    'ANALYTICS::CARD_REWARDS_AND_CREDITS_DETAILS::PAGE_VIEW';
export const ANALYTICS_CARD_REWARDS_AND_CREDITS_DETAILS_CLICK_BACK =
    'ANALYTICS::CARD_REWARDS_AND_CREDITS_DETAILS::CLICK_BACK';

// PERPAY CARD REWARD HISTORY PAGE
export const ANALYTICS_CARD_REWARDS_AND_CREDITS_DETAILS_CLICK_HISTORY =
    'ANALYTICS::CARD_REWARDS_AND_CREDITS_DETAILS::CLICK_HISTORY';
export const ANALYTICS_CARD_REWARD_HISTORY_PAGE_VIEW =
    'ANALYTICS::CARD_REWARD_HISTORY::PAGE_VIEW';
export const ANALYTICS_CARD_REWARD_HISTORY_CLICK_BACK =
    'ANALYTICS::CARD_REWARD_HISTORY::CLICK_BACK';
export const ANALYTICS_CARD_REWARD_HISTORY_CLICK_PAGINATION =
    'ANALYTICS::CARD_REWARD_HISTORY::CLICK_PAGINATION';

// PERPAY CARD MAKE ADDITIONAL PAYMENT
export const ANALYTICS_CARD_MAKE_ADDITIONAL_PAYMENT_CLICK_PAYMENT =
    'ANALYTICS::CARD::MAKE_ADDITIONAL_PAYMENT::CLICK_PAYMENT';

// PERPAY CARD MANAGEMENT
export const ANALYTICS_CARD_MANAGEMENT_CLICK_STATEMENTS =
    'ANALYTICS::CARD::MANAGEMENT::CLICK_STATEMENTS';
export const ANALYTICS_CARD_MANAGEMENT_CLICK_HELP =
    'ANALYTICS::CARD::MANAGEMENT::CLICK_HELP';
export const ANALYTICS_CARD_MANAGEMENT_CLICK_SETTINGS =
    'ANALYTICS::CARD::MANAGEMENT::CLICK_SETTINGS';
export const ANALYTICS_CARD_MAINTENANCE_PAGE_VIEW =
    'ANALYTICS::CARD::MAINTENANCE::PAGE_VIEW';
export const ANALYTICS_CARD_MAINTENANCE_CLICK_NUMBER =
    'ANALYTICS::CARD::MAINTENANCE::CLICK_NUMBER';

// PERPAY CARD TRANSACTIONS
export const ANALYTICS_CARD_TRANSACTIONS_VIEW_STATEMENTS_CLICK =
    'ANALYTICS::CARD_TRANSACTIONS::VIEW_STATEMENTS_CLICK';
export const ANALYTICS_CARD_VIEW_ALL_ACTIVITIES_CLICK =
    'ANALYTICS::CARD::CARD_DETAILS::VIEW_ALL_ACTIVITIES_CLICK';

export const ANALYTICS_CARD_SUMMARY_CLICK_CARD =
    'ANALYTICS::CARD::CARD_SUMMARY::CLICK_CARD';
export const ANALYTICS_CARD_SUMMARY_CLICK_CALL_TO_ACTION =
    'ANALYTICS::CARD::CARD_SUMMARY::CLICK_CALL_TO_ACTION';

export const ANALYTICS_CARD_SUMMARY_EMPTY_STATE_CLICK_CALL_TO_ACTION =
    'ANALYTICS::CARD::CARD_SUMMARY::EMPTY_STATE::CLICK_CALL_TO_ACTION';
export const ANALYTICS_CARD_SUMMARY_EMPTY_STATE_CLICK_CONTENT =
    'ANALYTICS::CARD::CARD_SUMMARY::EMPTY_STATE::CLICK_CONTENT';
export const ANALYTICS_CARD_DETAILS_PAGE_VIEW =
    'ANALYTICS::CARD::CARD_DETAILS::PAGE_VIEW';

// PERPAY CARD LEARN (EMPTY STATE) EVENTS
export const ANALYTICS_CARD_LEARN_PAGE_VIEW =
    'ANALYTICS::CARD::CARD_LEARN::PAGE_VIEW';
export const ANALYTICS_CARD_LEARN_APPLY_NOW_CLICK =
    'ANALYTICS::CARD::CARD_LEARN::APPLY_NOW_CLICK';
export const ANALYTICS_CARD_LEARN_CAROUSEL_SLIDE_VIEW =
    'ANALYTICS::CARD::CARD_LEARN::CAROUSEL_SLIDE_VIEW';

// PERPAY CARD ADDITIONAL PAYMENT V2
export const ANALYTICS_CREATE_AUTO_PAY_SCHEDULE_STEP_VIEW =
    'ANALYTICS::CREATE_AUTO_PAY_SCHEDULE::STEP_VIEW';
export const ANALYTICS_CREATE_AUTO_PAY_SCHEDULE_SELECT_PAYMENT_METHOD_CLICK_DIRECT_DEPOSIT_UPDATE =
    'ANALYTICS::CREATE_AUTO_PAY_SCHEDULE::SELECT_PAYMENT_METHOD::CLICK_DIRECT_DEPOSIT_UPDATE';
export const ANALYTICS_CREATE_AUTO_PAY_SCHEDULE_SELECT_PAYMENT_METHOD_CLICK_BANK_PAYMENT =
    'ANALYTICS::CREATE_AUTO_PAY_SCHEDULE::SELECT_PAYMENT_METHOD::CLICK_BANK_PAYMENT';
export const ANALYTICS_CREATE_AUTO_PAY_SCHEDULE_SELECT_PAYMENT_METHOD_CLICK_BACK_HEADER =
    'ANALYTICS::CREATE_AUTO_PAY_SCHEDULE::SELECT_PAYMENT_METHOD::CLICK_BACK_HEADER';
export const ANALYTICS_CREATE_AUTO_PAY_SCHEDULE_SELECT_BANK_CLICK_BACK_HEADER =
    'ANALYTICS::CREATE_AUTO_PAY_SCHEDULE::SELECT_BANK::CLICK_BACK_HEADER';
export const ANALYTICS_CREATE_AUTO_PAY_SCHEDULE_SELECT_BANK_CLICK_CONTINUE =
    'ANALYTICS::CREATE_AUTO_PAY_SCHEDULE::SELECT_BANK::CLICK_CONTINUE';
export const ANALYTICS_CREATE_AUTO_PAY_SCHEDULE_CREATE_BANK_CLICK_BACK_HEADER =
    'ANALYTICS::CREATE_AUTO_PAY_SCHEDULE::CREATE_BANK::CLICK_BACK_HEADER';
export const ANALYTICS_CREATE_AUTO_PAY_SCHEDULE_CREATE_BANK_CLICK_CONTINUE =
    'ANALYTICS::CREATE_AUTO_PAY_SCHEDULE::CREATE_BANK::CLICK_CONTINUE';
export const ANALYTICS_CREATE_AUTO_PAY_SCHEDULE_SELECT_FREQUENCY_CLICK_BACK_HEADER =
    'ANALYTICS::CREATE_AUTO_PAY_SCHEDULE::SELECT_FREQUENCY::CLICK_BACK_HEADER';
export const ANALYTICS_CREATE_AUTO_PAY_SCHEDULE_SELECT_FREQUENCY_CLICK_CONTINUE =
    'ANALYTICS::CREATE_AUTO_PAY_SCHEDULE::SELECT_FREQUENCY::CLICK_CONTINUE';
export const ANALYTICS_CREATE_AUTO_PAY_SCHEDULE_SELECT_DATE_CLICK_BACK_HEADER =
    'ANALYTICS::CREATE_AUTO_PAY_SCHEDULE::SELECT_DATE::CLICK_BACK_HEADER';
export const ANALYTICS_CREATE_AUTO_PAY_SCHEDULE_SELECT_DATE_CLICK_CONTINUE =
    'ANALYTICS::CREATE_AUTO_PAY_SCHEDULE::SELECT_DATE::CLICK_CONTINUE';
export const ANALYTICS_CREATE_AUTO_PAY_SCHEDULE_SELECT_AMOUNT_CLICK_BACK_HEADER =
    'ANALYTICS::CREATE_AUTO_PAY_SCHEDULE::SELECT_AMOUNT::CLICK_BACK_HEADER';
export const ANALYTICS_CREATE_AUTO_PAY_SCHEDULE_SELECT_AMOUNT_CLICK_MAKE_PAYMENT =
    'ANALYTICS::CREATE_AUTO_PAY_SCHEDULE::SELECT_AMOUNT::CLICK_MAKE_PAYMENT';
export const ANALYTICS_CREATE_AUTO_PAY_SCHEDULE_SUCCESS_CLICK_DASHBOARD_HEADER =
    'ANALYTICS::CREATE_AUTO_PAY_SCHEDULE::SUCCESS::CLICK_DASHBOARD_HEADER';
export const ANALYTICS_CREATE_AUTO_PAY_SCHEDULE_SUCCESS_CLICK_DASHBOARD =
    'ANALYTICS::CREATE_AUTO_PAY_SCHEDULE::SUCCESS::CLICK_DASHBOARD';
export const ANALYTICS_CREATE_AUTO_PAY_SCHEDULE_SELECT_AMOUNT_VIEW_MAKE_PAYMENT_MODAL =
    'ANALYTICS::CREATE_AUTO_PAY_SCHEDULE::SELECT_AMOUNT::VIEW_MAKE_PAYMENT_MODAL';
export const ANALYTICS_CREATE_AUTO_PAY_SCHEDULE_SELECT_AMOUNT_CLOSE_MAKE_PAYMENT_MODAL =
    'ANALYTICS::CREATE_AUTO_PAY_SCHEDULE::SELECT_AMOUNT::CLOSE_MAKE_PAYMENT_MODAL';

// PERPAY CARD STATEMENTS
export const ANALYTICS_CARD_STATEMENTS_PAGE_VIEW =
    'ANALYTICS::CARD::CARD_STATEMENTS::PAGE_VIEW';
export const ANALYTICS_CARD_STATEMENTS_DOWNLOAD =
    'ANALYTICS::CARD::CARD_STATEMENTS::DOWNLOAD';

// PERPAY CARD SETTINGS
export const ANALYTICS_CARD_SETTINGS_PAGE_VIEW =
    'ANALYTICS::CARD::CARD_SETTINGS::PAGE_VIEW';
export const ANALYTICS_DISABLE_AUTO_APPLY_MODAL_VIEW =
    'ANALYTICS::CARD::CARD_DISABLE_AUTO_APPLY::MODAL_VIEW';
export const ANALYTICS_TOGGLE_AUTO_APPLY =
    'ANALYTICS::CARD::CARD_SETTINGS::TOGGLE_AUTO_APPLY';
export const ANALYTICS_DISABLE_AUTO_APPLY_CLICK_SAVE =
    'ANALYTICS::CARD::CARD_DISABLE_AUTO_APPLY::CLICK_SAVE';
export const ANALYTICS_DISABLE_AUTO_APPLY_CLICK_DISCARD =
    'ANALYTICS::CARD::CARD_DISABLE_AUTO_APPLY::CLICK_DISABLE';

// UPDATE DIRECT DEPOSIT
export const ANALYTICS_DIRECT_DEPOSIT_OPEN_PINWHEEL_MODAL =
    'ANALYTICS::DIRECT_DEPOSIT::OPEN_PINWHEEL_MODAL';

export const ANALYTICS_DIRECT_DEPOSIT_SUCCESS_CLICK_DONE =
    'ANALYTICS::DIRECT_DEPOSIT::SUCCESS::CLICK_DONE';

export const ANALYTICS_DIRECT_DEPOSIT_CLI_SUCCESS_CLICK_DONE =
    'ANALYTICS::DIRECT_DEPOSIT::CLI_SUCCESS:CLICK_DONE';

export const ANALYTICS_DIRECT_DEPOSIT_ON_STEP_VIEWED =
    'ANALYTICS::DIRECT_DEPOSIT::ON_STEP_VIEWED';

export const ANALYTICS_DIRECT_DEPOSIT_UPDATE_CLICK_EDIT_EMPLOYER =
    'ANALYTICS::DIRECT_DEPOSIT::UPDATE::CLICK_EDIT_EMPLOYER';
export const ANALYTICS_DIRECT_DEPOSIT_UPDATE_CLICK_EDIT_AMOUNT =
    'ANALYTICS::DIRECT_DEPOSIT::UPDATE::CLICK_EDIT_AMOUNT';
export const ANALYTICS_DIRECT_DEPOSIT_UPDATE_CLICK_CONFIRM =
    'ANALYTICS::DIRECT_DEPOSIT::UPDATE::CLICK_CONFIRM';
export const ANALYTICS_DIRECT_DEPOSIT_UPDATE_CLICK_BACK =
    'ANALYTICS::DIRECT_DEPOSIT::UPDATE::CLICK_BACK';

export const ANALYTICS_DIRECT_DEPOSIT_MANUAL_SETUP_TRY_AGAIN_CLICK =
    'ANALYTICS::DIRECT_DEPOSIT::MANUAL_SETUP::CLICK_TRY_AGAIN';
export const ANALYTICS_DIRECT_DEPOSIT_MANUAL_SETUP_DONE_CLICK =
    'ANALYTICS::DIRECT_DEPOSIT::MANUAL_SETUP::CLICK_DONE';
export const ANALYTICS_DIRECT_DEPOSIT_MANUAL_SETUP_FORM_CLICK =
    'ANALYTICS::DIRECT_DEPOSIT::MANUAL_SETUP::CLICK_FORM';
export const ANALYTICS_DIRECT_DEPOSIT_MANUAL_SETUP_INSTRUCTIONS_CLICK =
    'ANALYTICS::DIRECT_DEPOSIT::MANUAL_SETUP::CLICK_INSTRUCTIONS';
export const ANALYTICS_DIRECT_DEPOSIT_MANUAL_SETUP_CLICK_DASHBOARD_HEADER =
    'ANALYTICS::DIRECT_DEPOSIT::MANUAL_SETUP::CLICK_DASHBOARD_HEADER';

export const ANALYTICS_DIRECT_DEPOSIT_EDIT_EMPLOYER_CLICK_SAVE =
    'ANALYTICS::DIRECT_DEPOSIT::EDIT_EMPLOYER::CLICK_SAVE';
export const ANALYTICS_DIRECT_DEPOSIT_EDIT_EMPLOYER_CLICK_SELF_ONBOARDING =
    'ANALYTICS::DIRECT_DEPOSIT::EDIT_EMPLOYER::CLICK_SELF_ONBOARDING';
export const ANALYTICS_DIRECT_DEPOSIT_EDIT_EMPLOYER_CLICK_BACK =
    'ANALYTICS::DIRECT_DEPOSIT::EDIT_EMPLOYER::CLICK_BACK';

export const ANALYTICS_DIRECT_DEPOSIT_SELECT_AMOUNT_CLICK_DONE =
    'ANALYTICS::DIRECT_DEPOSIT::SELECT_AMOUNT::CLICK_CONTINUE';
export const ANALYTICS_DIRECT_DEPOSIT_SELECT_AMOUNT_CLICK_BACK =
    'ANALYTICS::DIRECT_DEPOSIT::SELECT_AMOUNT::CLICK_BACK';

export const ANALYTICS_DIRECT_DEPOSIT_SELF_ONBOARDING_CLICK_ADD_COMPANY =
    'ANALYTICS::DIRECT_DEPOSIT::SELF_ONBOARDING::CLICK_ADD_COMPANY';
export const ANALYTICS_DIRECT_DEPOSIT_SELF_ONBOARDING_CLICK_BACK =
    'ANALYTICS::DIRECT_DEPOSIT::SELF_ONBOARDING::CLICK_BACK';

export const ANALYTICS_DIRECT_DEPOSIT_TURN_OFF_DEPOSIT_CLICK_DASHBOARD =
    'ANALYTICS::DIRECT_DEPOSIT::TURN_OFF_DEPOSIT::CLICK_DASHBOARD';
export const ANALYTICS_DIRECT_DEPOSIT_TURN_OFF_DEPOSIT_CLICK_UPDATE =
    'ANALYTICS::DIRECT_DEPOSIT::TURN_OFF_DEPOSIT::CLICK_UPDATE';

export const ANALYTICS_DIRECT_DEPOSIT_PAST_DUE_CLICK_RESTART =
    'ANALYTICS::DIRECT_DEPOSIT::PAST_DUE::CLICK_RESTART';
export const ANALYTCIS_DIRECT_DEPOSIT_PAST_DUE_CLICK_PAY =
    'ANALYTICS::DIRECT_DEPOSIT::PAST_DUE::CLICK_PAY';
export const ANALYTCIS_DIRECT_DEPOSIT_PAST_DUE_CLICK_BACK =
    'ANALYTICS::DIRECT_DEPOSIT::PAST_DUE::CLICK_BACK';
export const ANALYTCIS_DIRECT_DEPOSIT_PAST_DUE_LAUNCH_SELF_HELP_TOOL =
    'ANALYTICS::DIRECT_DEPOSIT::PAST_DUE::LAUCH_SELF_HELP_TOOL';

export const ANALYTICS_DIRECT_DEPOSIT_CARD_SUSPENDED_CLICK_DASHBOARD =
    'ANALYTICS::DIRECT_DEPOSIT::CARD_SUSPENDED::CLICK_DASHBOARD';
export const ANALYTICS_DIRECT_DEPOSIT_CARD_SUSPENDED_CLICK_UPDATE =
    'ANALYTICS::DIRECT_DEPOSIT::CARD_SUSPENDED::CLICK_UPDATE';

export const ANALYTICS_DIRECT_DEPOSIT_CLI_CLICK_CLOSE =
    'ANALYICS::DIRECT_DEPOSIT::CLI::CLICK_CLOSE';
export const ANALYTICS_DIRECT_DEPOSIT_CLI_CLICK_NOT_NOW =
    'ANALYICS::DIRECT_DEPOSIT::CLI::CLICK_NOT_NOW';
export const ANALYTICS_DIRECT_DEPOSIT_CLI_CLICK_ACCEPT =
    'ANALYICS::DIRECT_DEPOSIT::CLI::CLICK_ACCEPT';

// CARD REWARDS NOTIFICATION

export const ANALYTICS_CARD_REWARDS_NOTIFICATION_VIEW =
    'ANALYTICS::CARD_REWARDS_NOTIFICATION::VIEW';
export const ANALYTICS_CARD_REWARDS_NOTIFICATION_CLICK_CTA =
    'ANALYTICS::CARD_REWARDS_NOTIFICATION::CLICK_CTA';
export const ANALYTICS_CARD_REWARDS_NOTIFICATION_CLOSE =
    'ANALYTICS::CARD_REWARDS_NOTIFICATION::CLOSE';

/* ERROR BASED */
export const REPORT_ERROR = 'ERROR::REPORT';

/* UI STATE BASED */
export const PINWHEEL_SET_AMOUNT_TO_CHARGE = 'PINWHEEL::SET_AMOUNT_TO_CHARGE';

// CARD ACTIVITY
export const ANALYTICS_CARD_ACTIVITY_CLICK_PAGINATION =
    'ANALYTICS::CARD_ACTIVITY::CLICK_PAGINATION';
export const ANALYTICS_CARD_ACTIVITY_PAGE_VIEW =
    'ANALYTICS::CARD_ACTIVITY::PAGE_VIEW';

// MARKETPLACE ACTIVITY
export const ANALYTICS_MARKETPLACE_ACTIVITY_CLICK_PAGINATION =
    'ANALYTICS::MARKETPLACE_ACTIVITY::CLICK_PAGINATION';
export const ANALYTICS_MARKETPLACE_ACTIVITY_PAGE_VIEW =
    'ANALYTICS::MARKETPLACE_ACTIVITY::PAGE_VIEW';
export const ANALYTICS_MARKETPLACE_SUMMARY_CLICK_CARD =
    'ANALYTICS::MARKETPLACE::MARKETPLACE_SUMMARY::CLICK_CARD';
export const ANALYTICS_MARKETPLACE_SUMMARY_LEARN_CLICK_CARD =
    'ANALYTICS::MARKETPLACE::MARKETPLACE_SUMMARY_LEARN::CLICK_CARD';
export const ANALYTICS_MARKETPLACE_SUMMARY_CLICK_CALL_TO_ACTION =
    'ANALYTICS::MARKETPLACE::MARKETPLACE_SUMMARY::CLICK_CALL_TO_ACTION';
export const ANALYTICS_MARKETPLACE_SUMMARY_LEARN_CLICK_START_SHOPPING =
    'ANALYTICS::MARKETPLACE::MARKETPLACE_SUMMARY_LEARN::CLICK_START_SHOPPING';

// PUSH NOTIFICATIONS
export const ANALYTICS_ITERABLE_PUSH_NOTIFICATION_OPEN =
    'ANALYTICS::ITERABLE_PUSH_NOTIFICATION::OPEN';

// PARTNER HOSTED ONBOARDING

export const ANALYTICS_PARTNER_HOSTED_ONBOARDING_INELIGIBLE_STATE_MODAL_OPEN =
    'ANALYTICS::PARTNER_HOSTED_ONBOARDING::INELIGIBLE_STATE_MODAL::OPEN';
export const ANALYTICS_PARTNER_HOSTED_ONBOARDING_INELIGIBLE_STATE_MODAL_CLOSE =
    'ANALYTICS::PARTNER_HOSTED_ONBOARDING::INELIGIBLE_STATE_MODAL::CLOSE';
export const ANALYTICS_PARTNER_HOSTED_ONBOARDING_INELIGIBLE_STATE_MODAL_EDIT_ADDRESS =
    'ANALYTICS::PARTNER_HOSTED_ONBOARDING::INELIGIBLE_STATE::EDIT_ADDRESS';
export const STORE_PARTNER_HOSTED_ONBOARDING_REDIRECT_EXISTING_USER =
    'store/PARTNER_HOSTED_ONBOARDING_::REDIRECT_EXISTING_USER';

// CARD ONBOARDING EMPLOYER BASED REJECTIONS
export const ANALYTICS_CARD_ONBOARDING_EMPLOYER_REJECTION_STEP_VIEWED =
    'ANALYTICS::CARD_ONBOARDING::EMPLOYER_REJECTION::STEP_VIEW';

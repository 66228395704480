import { Subject } from 'rxjs';
import { decodeTokens, retrieveToken } from '@perpay-web/utils/tokenUtils';
import { noop } from '@perpay-web/utils/noop';
import { wrapInflight } from '@perpay-web/utils/promiseUtils';
import {
    requestAuthenticate,
    requestAuthenticateMFA,
    requestLogout,
    requestRefresh,
    requestResendMFA,
    requestSignup,
    requestPartnerHostedSignup,
} from '@perpay-web/authentication/requests';
import {
    getAccessToken,
    getRefreshToken,
    getDecodedTokens,
    getIsAuthenticated,
    getIsTokenExpired,
    getSignupRequirements,
    getMaxPays,
    getAvailableSpendingLimit,
    getIsPartnerOnboarded,
    getPartnerOnboardedCode,
    getPhoneNumber,
} from '@perpay-web/authentication/selectors';
import {
    AUTHENTICATED,
    UNAUTHENTICATED,
    REFRESHING,
} from '@perpay-web/authentication/constants/states';

const getInitialState = () => {
    const storedToken = retrieveToken() || {};
    const tokenState = getIsTokenExpired(storedToken.refresh)
        ? UNAUTHENTICATED
        : AUTHENTICATED;
    return {
        access: storedToken.access,
        refresh: storedToken.refresh,
        tokenState,
    };
};

const getNextTokenObservableValue = (previousState, nextState) => ({
    previousState,
    nextState,
});

/**
 * The AuthenticationService maintains the source of truth for authentication state.
 * It is responsible for managing authentication through signup, login, refresh and logout.
 * This function returns an instance of the service. Only one instance of the service
 * should be created in an application, though multiple can be created in appropriate circumstances
 * i.e. for testing.
 */
export const createAuthenticationService = () => {
    /**
     * Setup the service internal state
     */
    const token$ = new Subject();
    let state = getInitialState();
    const setState = (updatedState) => {
        const previousState = state;
        const nextState = { ...state, ...updatedState };
        state = nextState;
        token$.next(getNextTokenObservableValue(previousState, nextState));
    };

    const signup = (accountData) =>
        requestSignup(accountData).then((results) => {
            const { tokens, ...rest } = results;
            const decodedTokens = decodeTokens(tokens);
            setState({
                access: decodedTokens.access,
                refresh: decodedTokens.refresh,
                tokenState: AUTHENTICATED,
            });
            return rest;
        });

    const partnerHostedSignup = (UserData) =>
        requestPartnerHostedSignup(UserData).then((results) => {
            const { tokens, ...rest } = results;
            const decodedTokens = decodeTokens(tokens);
            setState({
                access: decodedTokens.access,
                refresh: decodedTokens.refresh,
                tokenState: AUTHENTICATED,
            });
            return rest;
        });

    const authenticate = (credentials) =>
        requestAuthenticate(credentials).then((decodedTokens) => {
            if (decodedTokens.mfa && decodedTokens.mfa.mfaRequired) {
                setState({
                    tokenState: UNAUTHENTICATED,
                });
            }

            if (decodedTokens.access && decodedTokens.refresh) {
                setState({
                    access: decodedTokens.access,
                    refresh: decodedTokens.refresh,
                    tokenState: AUTHENTICATED,
                });
            }

            // could be access and refresh tokens, or MFA token
            return decodedTokens;
        });

    const authenticateMFA = (payload) =>
        requestAuthenticateMFA(payload).then((decodedTokens) => {
            if (!decodedTokens.access || !decodedTokens.refresh) {
                setState({
                    tokenState: UNAUTHENTICATED,
                });
            } else {
                setState({
                    access: decodedTokens.access,
                    refresh: decodedTokens.refresh,
                    tokenState: AUTHENTICATED,
                });
            }

            return decodedTokens;
        });

    const logout = wrapInflight(() => {
        const promise =
            state.tokenState === UNAUTHENTICATED
                ? Promise.resolve()
                : requestLogout(getRefreshToken(state));

        const setLogoutState = () => {
            setState({
                access: {},
                refresh: {},
                tokenState: UNAUTHENTICATED,
            });
        };

        return promise.then(setLogoutState, setLogoutState);
    });

    const refresh = wrapInflight(() => {
        setState({
            tokenState: REFRESHING,
        });

        const refreshToken = getRefreshToken(state);
        const handleSuccess = (tokens) => {
            setState({
                access: tokens.access,
                tokenState: AUTHENTICATED,
            });
            return tokens;
        };
        // If the refresh request fails, we should not log out, because the request could be failing
        // because the page is navigating, and not because there was a server-side error.
        const handleError = noop;

        return requestRefresh(refreshToken).then(handleSuccess, handleError);
    });

    const getRefreshedValue = (getter) => {
        if (!getIsTokenExpired(state.access)) {
            return Promise.resolve(getter());
        }

        if (getIsTokenExpired(state.refresh)) {
            return Promise.reject(
                new Error('Refresh token expired. Cannot refresh.'),
            );
        }

        return refresh().then(() => getter());
    };

    const getRefreshedAccessToken = () =>
        getRefreshedValue(() => getAccessToken(state));

    const getRefreshedEmail = () =>
        getRefreshedValue(() => getDecodedTokens(state).access.payload.email);

    return {
        token$: token$.asObservable(),
        signup,
        partnerHostedSignup,
        authenticate,
        authenticateMFA,
        refresh,
        logout,
        getRefreshedAccessToken: () => getRefreshedAccessToken(),
        getRefreshedEmail: () => getRefreshedEmail(),
        getDecodedTokens: () => getDecodedTokens(state),
        getAccessToken: () => getAccessToken(state),
        getIsAccessTokenExpired: () => getIsTokenExpired(state.access),
        getIsRefreshTokenExpired: () => getIsTokenExpired(state.refresh),
        getIsAuthenticated: () => getIsAuthenticated(state),
        getIsPartnerOnboarded: () => getIsPartnerOnboarded(state),
        getPartnerOnboardedCode: () => getPartnerOnboardedCode(state),
        getPhoneNumber: () => getPhoneNumber(state),
        getSignupRequirements: () => getSignupRequirements(state),
        getMaxPays: () => getMaxPays(state),
        getAvailableSpendingLimit: () => getAvailableSpendingLimit(state),
        resendMFA: (payload) => requestResendMFA(payload),
    };
};

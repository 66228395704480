import { createContext } from 'react';

export const E2E_PARAM_NAME = 'data-e2e-test-id';

export const EndToEndTestIDContext = createContext('');

export const EndToEndTestIdProvider = ({ testId, children }) => (
    <EndToEndTestIDContext.Provider value={testId}>
        {children}
    </EndToEndTestIDContext.Provider>
);

// Get a spreadable props object for an end-to-end test id
export const getTestIdProps = (testId) => {
    if (!testId) {
        return {};
    }
    return { [E2E_PARAM_NAME]: testId };
};

import {
    switchMap,
    mergeMap,
    exhaustMap,
    delay,
    takeUntil,
} from 'rxjs/operators';
import { of, timer, merge, concat, EMPTY } from 'rxjs';
import { ofType } from 'redux-observable';
import { emitOrTimeout } from '@perpay-web/utils/observable';
import { dispatchObservable } from '@perpay-web/observable/dispatchObservable';
import { handleError } from '@perpay-web/observable/operators/handleError';
import { reduxStepsSetStep } from '@perpay-web/hooks/useReduxSteps';
import { replace } from '@perpay-web/services/router';
import { paths } from '@perpay-web/fintech/props/appPaths';
import { CARD_ACCOUNT_APPLICATION_ENDPOINT } from '@perpay-web/fintech/constants/urls';
import {
    fetchCardAccountApplication,
    fetchCardAccountApplicationSuccess,
    fetchCardAccountApplicationError,
} from '@perpay-web/fintech/actions/entities/cardAccountApplications';
import {
    fetchPartnerHostedCardApplication as fetchPartnerHostedCardApplicationAction,
    fetchPartnerHostedCardApplicationSuccess,
    fetchPartnerHostedCardApplicationError,
    partnerHostedApplicationStartPolling,
    partnerHostedApplicationPollingComplete,
    partnerHostedApplicationCancelPolling,
    partnerHostedApplicationPollingError,
} from '@perpay-web/fintech/actions/ui/partnerHostedCardApplication';

import { getPartnerOnboardingStepFromStatus } from '@perpay-web/fintech/utils/partnerOnboardingUtils';
import {
    COMPLETE,
    IN_REVIEW,
    NEW,
    PREFERENCES,
} from '@perpay-web/fintech/constants/cardAccountApplicationStatuses';
import * as flowSteps from '@perpay-web/fintech/constants/steps/cardPartnerOnboardingSteps';

export const fetchPartnerHostedCardApplication = (action$, state$) =>
    action$.pipe(
        ofType(fetchPartnerHostedCardApplicationAction().type),
        exhaustMap(() =>
            dispatchObservable({
                action$,
                state$,
                initialDispatch: () => [fetchCardAccountApplication()],
                waitFor: [fetchCardAccountApplicationSuccess().type],
                waitForDispatch: (state, results) => {
                    const cardAccountApplication = results[0].payload;
                    const returnActions = [
                        fetchPartnerHostedCardApplicationSuccess(
                            cardAccountApplication,
                        ),
                    ];
                    if (cardAccountApplication.status === COMPLETE) {
                        replace(paths.dashboard.path);
                    } else if (cardAccountApplication.status) {
                        returnActions.push(
                            reduxStepsSetStep(
                                Object.values(flowSteps),
                                getPartnerOnboardingStepFromStatus(
                                    cardAccountApplication.status,
                                ),
                            ),
                        );
                    } else {
                        returnActions.push(
                            reduxStepsSetStep(
                                Object.values(flowSteps),
                                flowSteps.APPLICATION_STEP,
                            ),
                        );
                    }
                    // If there is no application, then they are already on the right (Application) step of the flow.
                    return returnActions;
                },
                errors: [fetchCardAccountApplicationError().type],
                errorDispatch: (result) => {
                    const error = result.payload;
                    return [
                        fetchPartnerHostedCardApplicationError(error),
                        reduxStepsSetStep(
                            Object.values(flowSteps),
                            flowSteps.APPLICATION_ERROR_STEP,
                        ),
                    ];
                },
            }),
        ),
    );

export const partnerOnboardingPollApplicationStatus = (
    action$,
    state$,
    { patch },
) =>
    action$.pipe(
        ofType(partnerHostedApplicationStartPolling().type),
        switchMap((action) => {
            const cardAccountApplicationUUID = action.payload;

            const pollingTimeout$ = of(
                partnerHostedApplicationPollingComplete(),
            ).pipe(delay(20 * 1000));

            const cancelActions$ = action$.pipe(
                ofType(partnerHostedApplicationCancelPolling().type),
            );

            const timeoutSetStep$ = pollingTimeout$.pipe(
                takeUntil(cancelActions$),
                exhaustMap(() =>
                    emitOrTimeout(
                        patch(
                            `${CARD_ACCOUNT_APPLICATION_ENDPOINT}${cardAccountApplicationUUID}/`,
                            { fetch_deserve_status: true },
                        ),
                    ),
                ),
                mergeMap((results) => {
                    const cardAccountApplication = results.response;
                    const { status } = cardAccountApplication;
                    const reduxFlowKey = Object.values(flowSteps);

                    if (status === NEW) {
                        return [
                            reduxStepsSetStep(
                                reduxFlowKey,
                                flowSteps.APPLICATION_ERROR_STEP,
                            ),
                        ];
                    }
                    const step = getPartnerOnboardingStepFromStatus(status);
                    return [reduxStepsSetStep(reduxFlowKey, step)];
                }),
            );

            const stopPolling$ = merge(pollingTimeout$, cancelActions$);

            const poll$ = timer(0, 1000).pipe(
                takeUntil(stopPolling$),
                exhaustMap(() =>
                    emitOrTimeout(
                        patch(
                            `${CARD_ACCOUNT_APPLICATION_ENDPOINT}${cardAccountApplicationUUID}/`,
                            { fetch_deserve_status: true },
                        ),
                    ),
                ),
                mergeMap((results) => {
                    const cardAccountApplication = results.response;
                    const { status } = cardAccountApplication;
                    const reduxFlowKey = Object.values(flowSteps);
                    const reduxFlowStep =
                        getPartnerOnboardingStepFromStatus(status);

                    if (![NEW, IN_REVIEW, PREFERENCES].includes(status)) {
                        return [
                            partnerHostedApplicationCancelPolling(),
                            reduxStepsSetStep(reduxFlowKey, reduxFlowStep),
                            fetchPartnerHostedCardApplicationSuccess(
                                cardAccountApplication,
                            ),
                        ];
                    }
                    return EMPTY;
                }),
                handleError((error) => [
                    partnerHostedApplicationCancelPolling(),
                    partnerHostedApplicationPollingError(error),
                    reduxStepsSetStep(
                        Object.values(flowSteps),
                        flowSteps.APPLICATION_ERROR_STEP,
                    ),
                ]),
            );

            return concat(
                merge(poll$, timeoutSetStep$),
                of(partnerHostedApplicationPollingComplete()),
            );
        }),
    );

import { Children, cloneElement, createContext, useContext } from 'react';

/**
 * Returns a new component that nests each Provider argument.
 * This utility helps to reduce the level of nesting when adding multiple
 * React context Provider components to the app.
 *
 * ```
 * // Before
 * <ProviderA>
 *   <ProviderB>
 *     <ProviderC>
 *       <App />
 *     </ProviderC>
 *   </ProviderB>
 * </ProviderA>
 * ```
 *
 * ```
 * // After
 * const Providers = createCombinedProvider(ProviderA, ProviderB, ProviderC);
 *
 * <Providers>
 *   <App />
 * </Providers>
 * ```
 *
 * Adapted from https://dev.to/fariasmateuss/compose-multiple-react-providers-4oc4
 */
export const createCombinedProvider = (...providers) => {
    if (providers.length === 0) {
        return ({ children }) => children;
    }

    return providers.reduce(
        (ChildComponent, ParentComponent) =>
            ({ children }) => (
                <ParentComponent>
                    <ChildComponent>{children}</ChildComponent>
                </ParentComponent>
            ),
    );
};

// Hide runtime key warning.
// This is for use when we expect an array of React Components to change
// so infrequently that there cannot be a performance cost at runtime.
export const hideKeyWarning = (children) =>
    Children.map(children, (child, index) =>
        // eslint-disable-next-line react/no-array-index-key
        cloneElement(child, { key: index }),
    );

export const createContextProvider = (providerFactory) => {
    /**
     * Create the Context that will provide the state to the React component hierarchy.
     */
    const Context = createContext();

    /**
     * Create the Provider component using the factory function provided by the caller.
     * The caller will render the Provider to pass in the hook's state values.
     */
    const Provider = providerFactory(Context);

    /**
     * Allow components to directly access the state exposed by the Provider
     */
    const useContextHook = () => useContext(Context);

    /**
     * - ProviderWithState makes the context value available to the component hierarchy below,
     * and connects a stream of the changing context values to the hookState$ Observable.
     *
     * - useContextHook allows components to access the context value from the provider.
     *
     * Use the array-return pattern to allow callers to easily name these values.
     */
    return [Provider, useContextHook];
};
